import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ReactDOM from "react-dom";
import FocusLock from "react-focus-lock";
import { useTransition, animated, config } from "react-spring";
import { useKeyDown } from "../../hooks/useKeyEvent";
import * as easings from "d3-ease";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import SvgIcon from "../SvgIcon";
import CrossIcon from "../../../images/svg/cross.svg";
import ChevronIcon from "../../../images/svg/chevron.svg";
import Button from "../Button";
import Loader from "../Loader";
import { Link } from "react-router-dom";
import "./index.scss";

function Modal(props) {
  const {
    ariaLabel,
    ariaLabelledBy,
    backgroundClose,
    children,
    clipContent,
    closeLabel,
    compactActions,
    disableFocusLock,
    form,
    hideActions,
    hideX,
    inline,
    largeX,
    lightX,
    loading,
    onBackClick,
    onClick,
    onClose,
    onCloseDisabled,
    onCloseLink,
    onCloseLoading,
    onSubmit,
    onSubmitHref,
    onSubmitLink,
    overflowVisible,
    prompt,
    stopClickPropagation,
    style,
    submitDisabled,
    submitLabel,
    submitLoading,
    submitType,
    suppressZIndex,
    tabs,
    target,
    title,
    titleIcon,
    transparentBg,
    width,
    zIndex,
  } = props;

  const [ready, setReady] = useState(false);
  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    };
  }, []);

  const containerRef = useRef();

  useKeyDown("Escape", onClose, containerRef.current, false, true);

  useOnClickOutside(containerRef, () =>
    setTimeout(backgroundClose ? onClose : null, 100)
  );

  const handleClick = evt => {
    if (stopClickPropagation) evt.stopPropagation();
    if (onClick) onClick();
  };

  const transitions = useTransition(ready, {
    from: { opacity: 0.7, transform: `translate(-50%, -30%) scale(0.9)` },
    enter: { opacity: 1, transform: `translate(-50%, -50%) scale(1)` },
    config: { duration: 200, easing: easings.easeBackOut, ...config.stiff },
  });

  const showHeader = !!title || !!tabs;

  const className = classNames("modal-v2", {
    "modal-v2--transparent-bg": transparentBg,
    "modal-v2--inline": inline,
    "modal-v2--large-x": largeX,
    "modal-v2--light-x": lightX,
    "modal-v2--loading": loading,
    "modal-v2--suppress": suppressZIndex,
    "modal-v2--overflow-visible": overflowVisible,
    "modal-v2--compact-actions": compactActions,
    "modal-v2--header": showHeader,
    "modal-v2--prompt-no-title": !showHeader && prompt,
  });
  const showActions =
    (!!onSubmit ||
      !!onSubmitLink ||
      !!onSubmitHref ||
      ((onClose || onCloseLink) && closeLabel)) &&
    !hideActions;

  const content = (
    <Fragment>
      {prompt ? (
        <p
          className="modal-v2__prompt"
          dangerouslySetInnerHTML={{ __html: prompt }}
        />
      ) : null}
      {children}
    </Fragment>
  );

  const innerContent = (
    <Fragment>
      {showHeader ? (
        <header className={"modal-v2__header"}>
          <h1 className="modal-v2__title">
            {titleIcon ? <SvgIcon icon={titleIcon} /> : null}
            {title}
          </h1>
        </header>
      ) : null}
      {onBackClick ? (
        <button className="modal-v2__back" onClick={onBackClick} type="button">
          <SvgIcon icon={ChevronIcon} name="chevron" />
        </button>
      ) : null}
      {onClose && !hideX ? (
        <button className="modal-v2__close" onClick={onClose} type="button">
          <SvgIcon icon={CrossIcon} name="cross" />
        </button>
      ) : null}
      {onCloseLink && !hideX ? (
        <Link className="modal-v2__close" to={onCloseLink}>
          <SvgIcon icon={CrossIcon} name="cross" />
        </Link>
      ) : null}

      <div className="modal-v2__main scrollable">
        {loading ? (
          <div className="modal-v2__loading">
            <Loader />
          </div>
        ) : null}
        {clipContent ? (
          <div className="modal-v2__content-clip">{content}</div>
        ) : (
          content
        )}
      </div>
      {showActions ? (
        <div className="modal-v2__actions">
          {onClose && closeLabel ? (
            <Button
              type="secondary-text"
              onClick={onClose}
              htmlType="button"
              loading={onCloseLoading}
              disabled={submitLoading || onCloseDisabled}>
              {closeLabel}
            </Button>
          ) : null}
          {onCloseLink && closeLabel ? (
            <Button
              type="secondary-text"
              to={onCloseLink}
              htmlType="button"
              loading={onCloseLoading}
              disabled={submitLoading || onCloseDisabled}>
              {closeLabel}
            </Button>
          ) : null}
          {onSubmit || onSubmitLink || onSubmitHref ? (
            <Button
              type={submitType || "tertiary"}
              onClick={onSubmit}
              link={onSubmitLink}
              htmlType="submit"
              href={onSubmitHref}
              disabled={submitDisabled || submitLoading}
              loading={submitLoading}>
              {submitLabel}
            </Button>
          ) : null}
        </div>
      ) : null}
    </Fragment>
  );

  const component = (
    <FocusLock
      className={className}
      autoFocus={false}
      lockProps={{ style: { zIndex } }}
      disabled={disableFocusLock}>
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              role="dialog"
              aria-label={ariaLabel}
              aria-modal="true"
              aria-labelledby={ariaLabelledBy}
              style={{ width, ...styles, ...style }}
              onClick={handleClick}
              className="modal-v2__content"
              ref={containerRef}>
              <div className="modal-v2__content-bg" />

              {form ? (
                <form className="modal-v2__content-inner" onSubmit={onSubmit}>
                  {innerContent}
                </form>
              ) : (
                <div className="modal-v2__content-inner"> {innerContent}</div>
              )}
            </animated.div>
          )
      )}
    </FocusLock>
  );

  if (target && document.querySelector(target)) {
    return ReactDOM.createPortal(component, document.querySelector(target));
  } else {
    return component;
  }
}

Modal.propTypes = {
  backgroundClose: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  clipContent: PropTypes.bool,
  closeLabel: PropTypes.string,
  disableFocusLock: PropTypes.bool,
  largeX: PropTypes.bool,
  lightX: PropTypes.bool,
  onBackClick: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  overflowVisible: PropTypes.bool,
  style: PropTypes.object,
  submitLabel: PropTypes.string,
  submitDisabled: PropTypes.bool,
  submitLoading: PropTypes.bool,
  suppressZIndex: PropTypes.bool,
  target: PropTypes.string,
  transparentBg: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.number,
  ariaLabelledBy: PropTypes.string,
  ariaLabel: PropTypes.string,
};

Modal.defaultProps = {
  width: 388,
};

export default Modal;
