import React, { useState } from "react";
import TextBox from "../../../../common/components/Forms/TextBox";
import { BUTTON_DOMAIN } from "../../../../config";
import Modal from "../../../../common/components/Modal";

export default function EditButton(props) {
  const {
    buttonToEdit,
    onClose,
    organisationId,
    onSuccess,
    addErrorMessage,
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const [buttonName, setButtonName] = useState(buttonToEdit.name);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const response = await fetch(
        `${BUTTON_DOMAIN}/admin/${organisationId}/buttons/${buttonToEdit.id}`,
        {
          credentials: "include",
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: buttonName }),
        }
      );
      if (response.status === 200) {
        const button = await response.json();
        onSuccess(button);
        onClose();
      } else {
        throw new Error();
      }
    } catch (err) {
      addErrorMessage(
        "Your button could not be edited, please refresh and try again"
      );
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title="Edit Button name"
      onConfirm={handleSubmit}
      onSubmit={handleSubmit}
      submitDisabled={!buttonName.length}
      submitLabel="Save"
      onClose={onClose}
      submitLoading={submitting}
      closeLabel="Cancel">
      <div style={{ padding: "40px 40px 0" }}>
        <TextBox
          value={buttonName}
          disabled={submitting}
          label="Button name"
          autoFocus
          onChange={setButtonName}
        />
      </div>
    </Modal>
  );
}
