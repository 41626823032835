import React, { useState } from "react";
import { BUTTON_DOMAIN } from "../../../../config";
import { isValidDomain } from "../../../../utils/validators";
import MultilineImport from "../../multiline-import";

export default function AddApiKey(props) {
  const { onClose, organisationId, onAdd, addErrorMessage } = props;

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async domains => {
    setSubmitting(true);
    try {
      const response = await fetch(
        `${BUTTON_DOMAIN}/admin/${organisationId}/api-keys`,
        {
          credentials: "include",
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ authorisedDomains: domains }),
        }
      );
      setSubmitting(false);
      if (response.status === 201) {
        const key = await response.json();
        onAdd(key);
        onClose();
      } else {
        addErrorMessage("Your key could not be added.");
      }
    } catch (err) {
      addErrorMessage("Your key could not be added.");
      setSubmitting(false);
    }
  };

  return (
    <MultilineImport
      title="Add an API key"
      intro="Enter the domains you will be using the API keys with"
      validator={input => input && isValidDomain(input, true)}
      validSingleMessage="%0 domain ready to add"
      validMultipleMessage="%0 domains ready to add"
      invalidSingleMessage="%0 invalid domain"
      invalidMultipleMessage="%0 invalid domains"
      loading={submitting}
      onSubmit={handleSubmit}
      onClose={onClose}
      submitLabel="Create key"
    />
  );
}
