import React, { useState } from "react";
import { BUTTON_DOMAIN } from "../../../../config";
import ApiKey from "../../api-key";
import Modal from "../../../../common/components/Modal";

export default function APIKeysToRevoke(props) {
  const {
    addErrorMessage,
    keyToRevoke,
    onClose,
    organisationId,
    onRevokeSuccess,
  } = props;

  const [revoking, setRevoking] = useState(false);

  const handleRevokeKey = async () => {
    setRevoking(true);
    const response = await fetch(
      `${BUTTON_DOMAIN}/admin/${organisationId}/api-keys/${keyToRevoke.apiKey}`,
      {
        credentials: "include",
        method: "DELETE",
      }
    );
    switch (response.status) {
      case 204:
      case 409:
        onRevokeSuccess(keyToRevoke);
        onClose();
        break;
      case 404:
      default:
        addErrorMessage(
          "API key could not be revoked, please refresh and try again"
        );
        break;
    }
    setRevoking(false);
  };

  return (
    <Modal
      title="Revoke API key?"
      onSubmit={handleRevokeKey}
      submitLabel="Revoke"
      submitType="negative"
      submitLoading={revoking}
      submitDisabled={revoking}
      onClose={onClose}>
      <div style={{ padding: "20px 40px" }}>
        Are you sure you want to revoke the key{" "}
        <ApiKey>{keyToRevoke.apiKey}</ApiKey>
        ?
        <br />
        <br />
        This cannot be undone. Please make sure you’ve replaced any existing
        uses of this key with a new one before continuing.
      </div>
    </Modal>
  );
}
