import { Record } from "immutable";

import {
  SET_RESOURCE_AUTH_EXPIRES,
  RESET_RESOURCE_AUTH_EXPIRES,
  SET_IS_LOADING_RESOURCE_AUTH_EXPIRES,
} from "./action-types";

export const AnalyticsState = new Record({
  resourceAuthExpires: null,
  signedURLs: null,
  userSignedURLs: null,
  isLoading: false,
});

export function analyticsReducer(
  state = new AnalyticsState(),
  { payload, type }
) {
  const { expiryTime, userSignedUrls, signedURLs } = payload || {};
  switch (type) {
    case SET_RESOURCE_AUTH_EXPIRES:
      return state.merge({
        resourceAuthExpires: expiryTime,
        signedURLs: signedURLs,
        userSignedURLs: userSignedUrls,
      });
    case RESET_RESOURCE_AUTH_EXPIRES:
      return state.merge({
        resourceAuthExpires: null,
        signedURLs: null,
        userSignedURLs: null,
      });
    case SET_IS_LOADING_RESOURCE_AUTH_EXPIRES:
      return state.merge({
        isLoading: payload,
      });
    default:
      return state;
  }
}
