import React, { Component } from "react";
import classNames from "classnames";
import ReactSelect from "react-select";
import SvgIcon from "../../ui/svg-icon";
import ChevronIcon from "../../../../images/svg/chevron.svg";
import TriangleIcon from "../../../../images/svg/triangle.svg";
import "./index.scss";

export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAbove: false,
    };
  }

  handleOpen = () => {
    const { top } = this._arrow.getBoundingClientRect();
    const h = window.innerHeight;
    this.setState({
      openAbove: top + 100 > h,
    });
  };

  render() {
    const {
      onChange,
      value,
      noborder,
      options,
      className,
      placeholder,
      triangle,
    } = this.props;

    const { openAbove } = this.state;

    const arrow = triangle
      ? () => {
          return (
            <div ref={c => (this._arrow = c)}>
              <SvgIcon icon={TriangleIcon} />
            </div>
          );
        }
      : () => {
          return (
            <div ref={c => (this._arrow = c)}>
              <SvgIcon icon={ChevronIcon} />
            </div>
          );
        };

    let cssClass = classNames("dropdown", {
      [`${className}`]: className,
      triangle: triangle,
      "open-above": openAbove,
    });

    let controlStyles = {};

    if (noborder) {
      controlStyles.borderColor = "transparent";
    }

    return (
      <ReactSelect
        ref={c => (this._container = c)}
        onChange={onChange}
        value={value}
        className={cssClass}
        classNamePrefix="dropdown"
        placeholder={placeholder}
        currentColor="green"
        arrowRenderer={arrow}
        autosize
        styles={{
          control: (provided, state) => ({
            ...provided,
            ...controlStyles,
          }),
          dropdownIndicator: provided => ({
            ...provided,
            color: "#1374e2",
          }),
        }}
        onOpen={this.handleOpen}
        clearable={false}
        isSearchable={false}
        options={options}
      />
    );
  }
}
