import React, { useState, useEffect, useRef } from "react";
import uuid from "node-uuid";
import classNames from "classnames";
import EyeIcon from "src/images/svg/eye.svg";
import SvgIcon from "../ui/svg-icon";
import "./text-box.scss";

interface IProps {
  onChange?(value: string): void;
  autoFocus?: boolean;
  error?: string;
  id?: string;
  label?: string;
  maxLength?: number;
  multiline?: boolean;
  name?: string;
  shaded?: boolean;
  type?: string;
  value?: string;
}

function TextBox(props: IProps) {
  const {
    autoFocus,
    onChange,
    error,
    label,
    multiline,
    shaded,
    type,
    value,
    name,
    maxLength,
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [plainText, setPlainText] = useState(false);
  const [id] = useState(props.id || uuid.v4());
  const inputRef = useRef<any>();
  const isFilled = value && value.length;
  const isInvalid = error && error.length;

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const togglePlainText = evt => {
    setPlainText(!plainText);
    inputRef.current.focus();
    evt.preventDefault();
  };

  const containerClass = classNames("text-box", {
    "text-box--focused": isFocused,
    "text-box--filled": isFilled,
    "text-box--error": isInvalid,
    "text-box--shaded": shaded,
  });

  const inputClass = classNames("text-box__input", {
    "text-box__input--has-toggle": type === "password",
  });

  const sharedProps = {
    id,
    ref: inputRef,
    value: value,
    name,
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
    maxLength,
    onChange: e => onChange(e.target.value),
  };

  return (
    <div className={containerClass}>
      {label && (
        <label className="text-box__label" htmlFor={id}>
          {label}
        </label>
      )}
      {multiline ? (
        <textarea className="text-box__textarea" {...sharedProps} />
      ) : (
        <input
          className={inputClass}
          type={
            type === "password"
              ? plainText
                ? "text"
                : "password"
              : type
              ? type
              : "text"
          }
          {...sharedProps}
        />
      )}
      {type === "password" ? (
        <button
          onClick={togglePlainText}
          type="button"
          className="text-box__toggle">
          <SvgIcon icon={EyeIcon} name="eye" />
        </button>
      ) : null}
    </div>
  );
}

export default TextBox;
