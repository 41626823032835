import React from "react";
import Avatar from "../../../avatar";
import "./index.scss";
import BookshelfIcon from "src/images/svg/book-shelf.svg";
import BookspreadIcon from "src/images/svg/book-spread-curved.svg";
import InfoIcon from "src/images/svg/info--border.svg";
import SvgIcon from "../../../ui/svg-icon";
import ReactTooltip from "react-tooltip";
import { OrganisationRole } from "../const";
import StatusBadge from "src/views/components/ui/status-badge";
import { FeatureFlags } from "src/core/organisation/types";

interface LibraryHeaderProps {
  id: number;
  displayName: string;
  email: string;
  featureFlags: FeatureFlags;
  role: number;
  school?: string;
  totalLibCount: number;
  totalBookCount: number;
  getAvatarUrl?: (userId: number) => string;
}

const LibTooltipText =
  "Total number of active libraries, including shared libraries. Excludes archived libraries.";
const BookTooltipText =
  "Total of teacher and student books created in teacher-owned libraries. Excludes books created in shared libraries.";

export default function LibraryHeader({
  id,
  displayName,
  email,
  featureFlags,
  school,
  totalLibCount,
  totalBookCount,
  getAvatarUrl,
  role,
}: LibraryHeaderProps) {
  return (
    <div className="library-menu-header">
      <div className="library-menu-header__avatar">
        <Avatar name={displayName} url={getAvatarUrl(id)} size={86} />
      </div>
      <div className="library-menu-header__teacher">
        <h2 className="library-menu-header__teacher-name">{displayName}</h2>
        <a
          className="library-menu-header__teacher-email"
          href={`mailto:${email}`}>
          {email}
        </a>
        <p className="library-menu-header__teacher-school">{school}</p>
        {role === OrganisationRole.MANAGER && featureFlags.orgLibraries && (
          <StatusBadge status="Library Manager" />
        )}
      </div>
      <div className="library-menu-header__count">
        <div className="library-menu-header__count-item">
          <div className="library-menu-header__count-icon">
            <SvgIcon icon={BookshelfIcon} />
          </div>
          <div className="library-menu-header__count-desc">
            <div className="library-menu-header__count-desc-title">
              LIBRARIES
              <div
                className="library-menu-header__tooltip"
                data-tip
                data-for={"tooltip-libcount"}>
                <SvgIcon icon={InfoIcon} />
                <ReactTooltip id={"tooltip-libcount"} multiline={true}>
                  {LibTooltipText}
                </ReactTooltip>
              </div>
            </div>
            <div className="library-menu-header__count-desc-count">
              {totalLibCount}
            </div>
          </div>
        </div>
        <div className="library-menu-header__count-item">
          <div className="library-menu-header__count-icon">
            <SvgIcon icon={BookspreadIcon} />
          </div>
          <div className="library-menu-header__count-desc">
            <div className="library-menu-header__count-desc-title">
              BOOKS
              <div
                className="library-menu-header__tooltip"
                data-tip
                data-for={"tooltip-bookcount"}>
                <SvgIcon icon={InfoIcon} name="info" />
                <ReactTooltip id={"tooltip-bookcount"} multiline={true}>
                  {BookTooltipText}
                </ReactTooltip>
              </div>
            </div>
            <div className="library-menu-header__count-desc-count">
              {totalBookCount}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
