import { List } from "immutable";
import React, { useState } from "react";
import { createSelector } from "reselect";
import { connect } from "react-redux";
import { replaceAll } from "../../../utils/string";
import Confirmation from "./confirmation";
import Input from "./input";
import { getTotalAvailableLicenseCount } from "src/core/organisation/selectors";
import Modal from "../../../common/components/Modal";
import "./index.scss";
import { IRecord } from "./types";

export { IRecord };

interface IProps {
  initialValue?: string[];
  intro?: string;
  onSubmit(value: IRecord[] | string[]): void;
  onClose(): void;
  loading?: boolean;
  assignLicenses?: boolean;
  availableLicenses: number;
  validator(value: string): boolean;
  validSingleMessage: string;
  validMultipleMessage: string;
  invalidSingleMessage: string;
  invalidMultipleMessage: string;
  submitLabel: string;
  title: string;
}

function MultilineInput(props: IProps) {
  const {
    initialValue,
    intro,
    onSubmit,
    onClose,
    loading,
    validator,
    assignLicenses = false,
    availableLicenses,
    validSingleMessage,
    validMultipleMessage,
    invalidSingleMessage,
    invalidMultipleMessage,
    submitLabel,
    title,
  } = props;

  const initialRecords: IRecord[] = initialValue
    ? initialValue.map(email => ({ email }))
    : [];
  const [inputs, setInputs] = useState(List(initialRecords));
  const [showConfirmation, setShowConfirmation] = useState(false);

  const parseInput = input => {
    let processed = replaceAll(input, ",", "\n");
    processed = replaceAll(processed, ";", "\n");
    processed = processed.split("\n");
    return processed
      .filter(input => input.trim().length > 0)
      .map(input => input.trim());
  };

  const handleImport = input => {
    const lines = parseInput(input);
    const defaultLicense = availableLicenses >= lines.length;
    setInputs(List(lines.map(email => ({ email, license: defaultLicense }))));
    setShowConfirmation(true);
  };

  const onChange = (inputs: List<IRecord>) => {
    setInputs(inputs);
  };

  const handleConfirm = () => {
    const data = assignLicenses
      ? inputs.toArray()
      : inputs.toArray().map(i => i.email);
    onSubmit(data);
  };

  const handleDeleteInput = index => {
    setInputs(inputs.delete(index));
  };

  return (
    <Modal
      title={title}
      onBackClick={showConfirmation ? () => setShowConfirmation(false) : null}
      width={408}
      onClose={onClose}>
      <div className="multiline-import">
        {showConfirmation ? (
          <Confirmation
            inputs={inputs}
            onChange={onChange}
            onSubmit={handleConfirm}
            deleteInput={handleDeleteInput}
            loading={loading}
            assignLicenses={assignLicenses}
            availableLicenses={availableLicenses}
            validator={validator}
            validSingleMessage={validSingleMessage}
            validMultipleMessage={validMultipleMessage}
            invalidSingleMessage={invalidSingleMessage}
            invalidMultipleMessage={invalidMultipleMessage}
            submitLabel={submitLabel}
          />
        ) : (
          <Input
            onSubmit={handleImport}
            intro={intro}
            initialValue={inputs.map(input => input.email).join("\n")}
          />
        )}
      </div>
    </Modal>
  );
}

const mapStateToProps = createSelector(
  getTotalAvailableLicenseCount,
  availableLicenses => ({
    availableLicenses,
  })
);

export default connect(mapStateToProps)(MultilineInput);
