import superagent from "superagent";
import { PAYMENTS_DOMAIN } from "../../config";
import { replaceAll } from "../../utils/string";
import * as API from "./api";
import * as OrgsApi from "../organisation/orgs-api";

import {
  DESELECT_ALL_TEACHERS,
  DESELECT_TEACHERS,
  DOWNGRADE_USERS_SUCCESS,
  RECEIVE_TEACHER_DATA,
  REMOVE_TEACHERS,
  RESET_TEACHERS_STORE,
  SELECT_TEACHERS,
  SET_ASSIGNING_LICENSES,
  SET_CONFIRM_ASSIGN_LICENSES,
  SET_CONFIRM_REMOVE_LICENSES,
  SET_DOWNGRADING_USERS,
  SET_EMAIL_SENT,
  SET_EMAIL_TEMPLATE,
  SET_EMAILING_TEACHERS,
  SET_IMPORTING_TEACHERS,
  SET_REMOVING_LICENSES,
  SET_REMOVING_TEACHERS,
  SET_SHOW_TEACHER_IMPORT,
  SET_SORT_BY,
  SET_TEACHERS_TO_EMAIL,
  SET_TEACHERS_TO_REMOVE,
  SET_USER_ROLE,
  SET_USERS_TO_DOWNGRADE,
  TOGGLE_TEACHER_SELECTION,
} from "./action-types";

import { getLicenseInfo, getOrganisationId } from "../organisation";
import { loadLicenses } from "../organisation/actions";
import { addErrorMessage } from "../notifications/actions";
import { OrganisationRole } from "../../views/components/teachers/library-menu/const";
import { getUserId } from "../auth";

export function loadTeachers() {
  return (dispatch, getState) => {
    const orgId = getOrganisationId(getState());
    superagent
      .get(
        `${PAYMENTS_DOMAIN}/v1/admin/organisations/${orgId}/members/teachers`
      )
      .withCredentials()
      .end((err, res) => {
        if (err) {
          switch (err.status) {
            default:
              dispatch({
                type: RECEIVE_TEACHER_DATA,
                payload: null,
              });
          }
        } else {
          dispatch({
            type: RECEIVE_TEACHER_DATA,
            payload: res.body.members,
          });
        }
      });
  };
}

export function setSortBy(payload) {
  return {
    type: SET_SORT_BY,
    payload,
  };
}

export function toggleTeacherSelection(ids) {
  return {
    type: TOGGLE_TEACHER_SELECTION,
    payload: ids,
  };
}

export function selectTeachers(ids) {
  return {
    type: SELECT_TEACHERS,
    payload: ids,
  };
}

export function deselectTeachers(ids) {
  return {
    type: DESELECT_TEACHERS,
    payload: ids,
  };
}

export function deselectAllTeachers() {
  return {
    type: DESELECT_ALL_TEACHERS,
  };
}

export function setLicensesToAssign(payload) {
  return {
    type: SET_CONFIRM_ASSIGN_LICENSES,
    payload,
  };
}

export function setLicensesToRemove(payload) {
  return {
    type: SET_CONFIRM_REMOVE_LICENSES,
    payload,
  };
}

export function importTeachers(payload) {
  return async (dispatch, getState) => {
    const teachers = payload.teachers.map(t => t.email);
    const emailsToLicense = new Set(
      payload.teachers.filter(t => t.license).map(t => t.email)
    );
    const orgId = getOrganisationId(getState());
    const authUserId = getUserId(getState());

    dispatch({ type: SET_IMPORTING_TEACHERS, payload: true });
    try {
      // TODO: implement orgs api read for importTeachers. Can we do licensing in the backend instead?
      const response = await API.importTeachers(orgId, teachers);
      let teachersToLicense = [
        ...response.body.newMembers,
        ...response.body.existingMembers,
      ]
        .filter(m => emailsToLicense.has(m.email))
        .map(m => m.id);
      const licenses = getLicenseInfo(getState());

      for (const license of licenses) {
        if (teachersToLicense.length === 0) break;
        const licenseCount = license.quantity - license.allocated;
        if (licenseCount === 0) continue;
        const teachersForThisLicense = teachersToLicense.slice(0, licenseCount);
        teachersToLicense = teachersToLicense.slice(licenseCount);

        const licenseRes = await OrgsApi.assignLicenses(
          orgId,
          teachersForThisLicense,
          authUserId
        );
        if (!licenseRes.ok) {
          dispatch(addErrorMessage(licenseRes.message));
          // throw error to handle in catch
          throw new Error("GraphQL query had errors");
        }
      }

      dispatch({
        type: SET_SHOW_TEACHER_IMPORT,
        payload: false,
      });
      dispatch({ type: SET_IMPORTING_TEACHERS, payload: false });
      dispatch(loadTeachers());
      dispatch(loadLicenses());
    } catch (err) {
      // TODO: fix error handling once we get importTeachers from Orgs Api
      switch (err?.status) {
        case 403:
          dispatch(addErrorMessage("Your account cannot perform this action"));
          break;
        case 409:
          dispatch(
            addErrorMessage("The teachers couldn't be granted licenses")
          );
          break;
        default:
          dispatch(
            addErrorMessage(
              "Something went wrong, the teachers couldn't be imported"
            )
          );
          break;
      }
      dispatch({ type: SET_IMPORTING_TEACHERS, payload: false });
      dispatch(loadLicenses());
    }
  };
}

export function removeTeachers(payload) {
  return async (dispatch, getState) => {
    const { teachers } = payload;
    const orgId = getOrganisationId(getState());
    const authUserId = getUserId(getState());

    dispatch({ type: SET_REMOVING_TEACHERS, payload: true });

    const response = await OrgsApi.deleteMembership(
      orgId,
      teachers,
      authUserId
    );

    dispatch({ type: SET_REMOVING_TEACHERS, payload: false });
    if (!response.ok) {
      dispatch(addErrorMessage(response.message));
    } else {
      dispatch({ type: REMOVE_TEACHERS, payload: teachers });
    }
    dispatch(deselectAllTeachers());
    dispatch(setTeachersToRemove(null));
    dispatch(loadTeachers());
    dispatch(loadLicenses());
  };
}

export function assignLicenses(payload) {
  return async (dispatch, getState) => {
    const { userIds } = payload;
    const orgId = getOrganisationId(getState());
    const authUserId = getUserId(getState());

    dispatch({ type: SET_ASSIGNING_LICENSES, payload: true });
    const response = await OrgsApi.assignLicenses(orgId, userIds, authUserId);

    dispatch({ type: SET_ASSIGNING_LICENSES, payload: false });
    if (!response.ok) {
      dispatch(addErrorMessage(response.message));
    } else {
      dispatch(loadTeachers());
      dispatch(setLicensesToAssign(null));
      dispatch(deselectAllTeachers());
      dispatch(loadLicenses());
    }
  };
}

export function removeLicenses(payload) {
  return async (dispatch, getState) => {
    const { teachers } = payload;
    const orgId = getOrganisationId(getState());
    const authUserId = getUserId(getState());

    dispatch({ type: SET_REMOVING_LICENSES, payload: true });
    const reponse = await OrgsApi.removeLicenses(orgId, teachers, authUserId);

    dispatch({ type: SET_REMOVING_LICENSES, payload: false });
    if (!reponse.ok) {
      dispatch(addErrorMessage(reponse.message));
    } else {
      dispatch(loadTeachers());
      dispatch(setLicensesToRemove(null));
      dispatch(deselectAllTeachers());
      dispatch(loadLicenses());
    }
  };
}

export function setShowTeacherImport(payload) {
  return {
    type: SET_SHOW_TEACHER_IMPORT,
    payload,
  };
}

export function setTeachersToRemove(payload) {
  return {
    type: SET_TEACHERS_TO_REMOVE,
    payload,
  };
}

export function setTeachersToEmail(payload) {
  return {
    type: SET_TEACHERS_TO_EMAIL,
    payload,
  };
}

export function setTeachersEmailing(payload) {
  return {
    type: SET_EMAILING_TEACHERS,
    payload,
  };
}

export function setEmailTemplate(payload) {
  return {
    type: SET_EMAIL_TEMPLATE,
    payload,
  };
}

export function sendWelcomeEmail(payload) {
  return async (dispatch, getState) => {
    dispatch(setTeachersEmailing(true));
    const orgId = getOrganisationId(getState());
    const authUserId = getUserId(getState());

    const { teachers, subject, message } = payload;
    const postPayload = {
      subject,
      body: {
        plain: message,
        html: replaceAll(message, /\r|\n/, "<br>"),
      },
      members: teachers,
    };

    const response = await OrgsApi.sendEmail(orgId, postPayload, authUserId);
    dispatch(setTeachersEmailing(false));

    if (!response.ok) {
      dispatch(
        addErrorMessage(response.message || "Error sending welcome email")
      );
    } else {
      if (response.data.errors?.length) {
        dispatch(
          addErrorMessage(
            "Some users selected have student accounts and weren't emailed"
          )
        );
      }
      dispatch(setEmailSent(true));
      dispatch(deselectAllTeachers());
    }
  };
}

export function toggleLibraryManager(payload) {
  return async (dispatch, getState) => {
    const { id, value } = payload;
    const role = value ? OrganisationRole.MANAGER : OrganisationRole.TEACHER;
    const currentRole = value
      ? OrganisationRole.TEACHER
      : OrganisationRole.MANAGER;
    const authUserId = getUserId(getState());
    const orgId = getOrganisationId(getState());
    dispatch({
      type: SET_USER_ROLE,
      payload: {
        id,
        role,
      },
    });

    const response = await OrgsApi.updateMembershipRole(
      orgId,
      id,
      role,
      authUserId
    );

    if (!response.ok) {
      dispatch(addErrorMessage(response.message || "Error setting user role"));
      dispatch({
        type: SET_USER_ROLE,
        payload: {
          id,
          role: currentRole,
        },
      });
    }
  };
}

export function setEmailSent(payload) {
  return {
    type: SET_EMAIL_SENT,
    payload,
  };
}

export function setUsersToDowngrade(payload) {
  return {
    type: SET_USERS_TO_DOWNGRADE,
    payload,
  };
}

export function setDowngradingUsers(payload) {
  return {
    type: SET_DOWNGRADING_USERS,
    payload,
  };
}

export function onDowngradeSuccess(payload) {
  return dispatch => {
    dispatch({
      type: DOWNGRADE_USERS_SUCCESS,
      payload: [payload],
    });
    dispatch(deselectTeachers([payload]));
  };
}

export function reset() {
  return {
    type: RESET_TEACHERS_STORE,
  };
}
