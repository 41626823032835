import React, { useEffect, useState, useRef } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import Helmet from "react-helmet";
import Form from "../../../components/forms/form";
import Message from "../../ui/message";
import FormButton from "../../../components/forms/form-button";
import { firebaseAuth } from "../../../../core/firebase";
import { BOOKCREATOR_AUTH_URL } from "../../../../config";
import "./index.scss";

function VerifyEmail(props) {
  const email = useSelector(state => state.auth.unverified);

  const [emailSent, setEmailSent] = useState(false);
  const [emailSending, setEmailSending] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(null);

  const intervalRef = useRef();

  const checkAuth = () => {
    const { currentUser } = firebaseAuth;
    if (currentUser && currentUser.emailVerified) {
      setRedirect("/sign-in");
    }
  };

  useEffect(() => {
    if (!email) {
      setRedirect("/sign-in");
    }
  }, [email]);

  useEffect(() => {
    intervalRef.current = setInterval(checkAuth, 10000);
    checkAuth();
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const resend = async () => {
    setEmailSending(true);
    setError(null);
    try {
      await fetch(
        `${BOOKCREATOR_AUTH_URL}/resend-email/${encodeURIComponent(email)}`,
        { method: "POST", credentials: "include" }
      );
      setEmailSent(true);
      setTimeout(() => {
        setEmailSent(false);
      }, 10000);
      setEmailSending(false);
    } catch (err) {
      setError("Something went wrong resending the email. Please try again");
      setEmailSending(false);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Form header="Verify your email" className="verify-email">
      <Helmet title="Email verification" />
      <div className="verify-email__content form__content">
        <Message
          message="You need to verify your email to use this site. We have sent you an
            email, please click the link in it to continue."></Message>
        <div className="verify-email__message">
          {error ? <Message type="error" message={error} /> : null}
          {emailSent ? (
            <Message
              type="info"
              message="Verification email has been resent!"
            />
          ) : null}
        </div>
      </div>
      <div className="verify-email__not-received">
        <small className="verify-email__not-received-smallprint">
          Not received a verification email yet? &nbsp;
        </small>
        <button
          className="verify-email__not-received-resend"
          onClick={resend}
          type="button"
          data-testid="email-verification__button--resend"
          disabled={emailSending}>
          Resend email
        </button>
      </div>
      <div className="form__actions">
        <FormButton
          flat
          href={{
            pathname: `/sign-in/email/${decodeURIComponent(email)}`,
            state: { loginRedirect: true },
          }}
          text="Back"
        />
      </div>
    </Form>
  );
}

export default withRouter(VerifyEmail);
