import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTransition, animated, config } from "react-spring";
import * as easings from "d3-ease";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import "./index.scss";

const duration = 200;

function Menu(props) {
  const {
    children,
    disableCloseOnClick,
    inside,
    large,
    maxHeight,
    position,
    width,
    onClose,
    style,
  } = props;

  const [ready, setReady] = useState(false);
  const [adjustedPosition, setAdjustedPosition] = useState(position);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [positioned, setPositioned] = useState(false);
  const containerRef = useRef();
  const outerRef = useRef();

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    };
  }, []);

  // Check menu won't show off screen
  useEffect(() => {
    if (ready && containerRef.current && outerRef.current) {
      let nextPosition = position;
      const margin = 30;
      const topMargin = 90; // Don't allow menu to go under <Header />
      const containerRect = containerRef.current.getBoundingClientRect();
      const outerRect = outerRef.current.getBoundingClientRect();
      // Menu starts animation at 90% scale so need to calculate full height
      const fullHeight = containerRect.height * 1.11;
      const fullWidth = containerRect.width * 1.11;
      const offScreenBottom =
        fullHeight + outerRect.y + margin > window.innerHeight;
      const offScreenRight =
        fullWidth + outerRect.x + margin > window.innerWidth;
      const offScreenLeft = outerRect.x - fullWidth - margin < 0;
      const offScreenTop = outerRect.y - fullHeight - topMargin < 0;
      if (offScreenBottom) {
        nextPosition = nextPosition.replace("s", "n");
      }
      if (offScreenTop) {
        nextPosition = nextPosition.replace("n", "s");
      }
      if (offScreenRight) {
        nextPosition = nextPosition.replace("e", "w");
      }
      if (offScreenLeft) {
        nextPosition = nextPosition.replace("w", "e");
      }
      setAdjustedPosition(nextPosition);
      setPositioned(true);
    }
  }, [ready, containerRef, outerRef, position]);

  useOnClickOutside(containerRef, () => setTimeout(onClose, 100));

  const className = classNames("menu-v2", {
    [`menu-v2--${adjustedPosition}`]: adjustedPosition,
    "menu-v2--large": large,
  });

  const translateYFrom =
    adjustedPosition.indexOf("s") > -1
      ? "-5%"
      : adjustedPosition.indexOf("n") > -1
      ? "5%"
      : adjustedPosition === "e" || adjustedPosition === "w"
      ? "-50%"
      : 0;
  const translateYTo =
    adjustedPosition === "e" || adjustedPosition === "w" ? "-50%" : 0;
  const translateXFrom =
    adjustedPosition.indexOf("e") > -1
      ? "-5%"
      : adjustedPosition.indexOf("w") > -1
      ? "5%"
      : adjustedPosition === "s" || adjustedPosition === "n"
      ? "-50%"
      : 0;
  const translateXTo =
    adjustedPosition === "s" || adjustedPosition === "n" ? "-50%" : 0;

  const from = `translate(${translateXFrom},${translateYFrom}) scale(0.9)`;
  const to = `translate(${translateXTo},${translateYTo}) scale(1)`;

  useEffect(() => {
    // explicity set transform after animation so position updates
    // correctly if prop changes
    if (ready) {
      setTimeout(() => {
        setAnimationComplete(true);
      }, duration);
    }
  }, [ready]);

  const transitions = useTransition(ready, {
    from: { opacity: 0.7, transform: from },
    enter: { opacity: 1, transform: to },
    config: { duration, easing: easings.easeBackOut, ...config.stiff },
  });

  const marginBottom =
    adjustedPosition.indexOf("n") > -1 && inside ? -30 : null;
  const marginTop = adjustedPosition.indexOf("s") > -1 && inside ? -30 : null;

  let containerStyle = {
    width,
    ...style,
    marginBottom,
    marginTop,
    maxHeight,
    visibility: !positioned ? "hidden" : null,
  };

  if (animationComplete) {
    containerStyle.transform = to;
  }

  return (
    <div ref={outerRef} onClick={evt => evt.stopPropagation()}>
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={{ ...styles, ...containerStyle }}
              className={className}
              onClick={!disableCloseOnClick ? onClose : null}
              ref={containerRef}>
              {children}
            </animated.div>
          )
      )}
    </div>
  );
}

Menu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  position: PropTypes.oneOf(["n", "ne", "e", "se", "s", "sw", "w", "nw"]),
};

Menu.defaultProps = {
  width: 250,
  position: "se",
};

export default Menu;
