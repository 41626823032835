import React, { Component } from "react";
import Table from "../../table";
import SelectionHeader from "../../selection-header";
import SelectionHeaderLabel from "../../selection-header/label";
import DropdownMenu from "../../ui/dropdown-menu";
import HeaderButton from "../../button/header";
import TruncatedText from "../../ui/truncated-text";
import RowActions from "../row-actions";
import MenuItems from "../menu-items";

export default class TeacherTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: {
        headings: [
          {
            name: "Email",
            sortable: "email",
          },
          {
            name: "Organization",
            sortable: "organisation",
          },
          {
            name: "",
            width: 100,
          },
        ],
        body: null,
      },
      sortBy: props.sortBy ? props.sortBy : props.defaultSortBy,
      bulkAction: null,
      menuOpen: null,
      adminsToRemove: null,
    };
  }

  getAdminRows = props => {
    const { admins, selectedAdmins, setAdminsToRemove } = props;

    const { menuOpen } = this.state;

    return admins.map(admin => {
      const adminJS = admin.toJS();
      const { email, organisation } = adminJS;
      return {
        ...adminJS,
        selected: selectedAdmins.indexOf(email) > -1,
        sortData: {
          email,
          organisation,
        },
        rowId: email,
        cells: [
          <a className="fs-mask" href={`mailto:${email}`}>
            {email}
          </a>,
          <TruncatedText className="fs-mask">{organisation}</TruncatedText>,
          <RowActions
            menuOpen={menuOpen}
            admin={adminJS}
            setAdminsToRemove={setAdminsToRemove}
          />,
        ],
      };
    });
  };

  componentWillMount() {
    this.setState({
      tableData: {
        ...this.state.tableData,
        body: this.getAdminRows(this.props),
      },
      sortBy: this.props.sortBy ? this.props.sortBy : this.props.defaultSortBy,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      tableData: {
        ...this.state.tableData,
        body: this.getAdminRows(nextProps),
      },
      sortBy: nextProps.sortBy ? nextProps.sortBy : nextProps.defaultSortBy,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedAdmins &&
      !this.props.selectedAdmins.size &&
      prevProps.selectedAdmins.size
    ) {
      this.resetBulkAction();
    }
  }

  openMenu = id => {
    this.setState({
      menuOpen: id,
    });
  };

  closeMenu = id => {
    if (this.state.menuOpen === id) {
      this.setState({
        menuOpen: null,
      });
    }
  };

  handleRowSelect = emails => {
    this.props.toggleAdminSelection(emails);
  };

  handleSelectAll = emails => {
    this.props.selectAdmins(emails);
  };

  handleDeselectAdmins = emails => {
    this.props.deselectAdmins(emails);
  };

  handleDeselectAllAdmins = () => {
    this.props.deselectAllAdmins();
  };

  handleBulkAction = () => {
    const { selectedAdmins, setAdminsToRemove } = this.props;
    const { bulkAction } = this.state;
    switch (bulkAction) {
      case "Remove admin":
        setAdminsToRemove(selectedAdmins.toJS());
        break;
      default:
        return;
    }
  };

  resetBulkAction = () => {
    this.setState({
      bulkAction: null,
    });
  };

  getHeaderMenuItems = () => {
    return (
      <MenuItems
        onRemoveAdminClick={e => this.setState({ bulkAction: "Remove admin" })}
      />
    );
  };

  render() {
    const { tableData, bulkAction, sortBy } = this.state;
    const { selectedAdmins, setSortBy } = this.props;

    return (
      <div>
        {selectedAdmins.size ? (
          <SelectionHeader onCancel={this.handleDeselectAllAdmins}>
            <SelectionHeaderLabel>
              <strong>{selectedAdmins.size}</strong>{" "}
              {selectedAdmins.size === 1 ? "admin" : "admins"} selected
            </SelectionHeaderLabel>
            <DropdownMenu
              placeholder="Bulk Actions"
              value={bulkAction}
              width={168}
              maxWidth={250}
              onChange={value => {
                this.setState({ bulkAction: value });
              }}>
              {this.getHeaderMenuItems()}
            </DropdownMenu>
            <HeaderButton
              onClick={this.handleBulkAction}
              disabled={bulkAction === null}>
              Apply
            </HeaderButton>
          </SelectionHeader>
        ) : null}
        <Table
          data={tableData}
          sortBy={sortBy}
          selectable
          rowHeight={58}
          onRowSelect={this.handleRowSelect}
          onSelectAll={this.handleSelectAll}
          onDeselectAll={this.handleDeselectAdmins}
          onDeselectRows={this.handleDeselectAdmins}
          resetSelection={this.handleDeselectAllAdmins}
          onHeadingClick={setSortBy}
          selectedRows={selectedAdmins}
        />
      </div>
    );
  }
}
