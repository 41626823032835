import React from "react";
import "./index.scss";

export default function ApiKey(props) {
  const { children, style } = props;

  return (
    <span className="api-key" style={style}>
      {children}
    </span>
  );
}
