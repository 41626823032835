import React from "react";
import Table from "../../table";
import { List } from "immutable";
import RowActions from "./row-actions";
import CopyToClipboard from "../../copy-to-clipboard";
import ApiKey from "../../api-key";
import "./index.scss";
import { getApiTime } from "../../../../utils/date";

export default function APIKeysTable(props) {
  const { apiKeys, setKeyToRevoke, setKeyToEdit } = props;

  const hasRevokedKey = apiKeys.find(key => !!key.revokedAt);

  const rows = new List(
    apiKeys.map(key => {
      const { apiKey, authorisedDomains, createdAt, revokedAt } = key;
      let cells = [
        <ApiKey>
          <span className="fs-mask">{apiKey}</span>
          {!revokedAt ? (
            <CopyToClipboard
              text={apiKey}
              style={{
                position: "absolute",
                left: "100%",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            />
          ) : null}
        </ApiKey>,
        <ul className="domain-list">
          {authorisedDomains.map(domain => (
            <li key={domain} className="fs-mask">
              {domain}
            </li>
          ))}
        </ul>,
        <span className="fs-mask">{getApiTime(createdAt)}</span>,
      ];
      if (hasRevokedKey) {
        cells.push(
          revokedAt ? (
            <span className="fs-mask">{getApiTime(revokedAt)}</span>
          ) : null
        );
      }
      if (!revokedAt) {
        cells.push(
          <RowActions
            apiKey={key}
            setKeyToRevoke={setKeyToRevoke}
            setKeyToEdit={setKeyToEdit}
          />
        );
      }
      return {
        ...key,
        rowId: apiKey,
        cells,
        className: revokedAt ? "revoked" : null,
      };
    })
  );

  let headings = [
    {
      name: "API Key",
      width: 500,
    },
    {
      name: "Domains",
    },
    {
      name: "Created",
    },
  ];

  if (hasRevokedKey) {
    headings.push({ name: "Revoked" });
  }

  headings.push({
    name: "",
    width: 100,
  });

  return (
    <div>
      <Table
        className="api-key-table"
        data={{
          headings,
          body: rows,
        }}
        rowHeight={58}
      />
    </div>
  );
}
