import React from "react";
import Table from "../../table";
import { List } from "immutable";
import RowActions from "./row-actions";
import CopyToClipboard from "../../copy-to-clipboard";
import ApiKey from "../../api-key";
import "./index.scss";
import { getApiTime } from "../../../../utils/date";

export default function ButtonsTable(props) {
  const { buttons, setButtonToDelete, setButtonToEdit } = props;

  const rows = new List(
    buttons.map(button => {
      const { id, name, createdAt } = button;
      let cells = [
        <span className="fs-mask">{name}</span>,
        <ApiKey>
          <span className="fs-mask">{id}</span>{" "}
          <CopyToClipboard
            text={id}
            style={{
              position: "absolute",
              left: "100%",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          />
        </ApiKey>,
        <span className="fs-mask">{getApiTime(createdAt)}</span>,
        <RowActions
          button={button}
          setButtonToDelete={setButtonToDelete}
          setButtonToEdit={setButtonToEdit}
        />,
      ];

      return {
        ...button,
        rowId: id,
        cells,
      };
    })
  );

  let headings = [
    {
      name: "Name",
      width: "20%",
    },
    {
      name: "ID",
    },
    {
      name: "Created",
      width: "25%",
    },
    {
      name: "",
      width: 80,
    },
  ];

  return (
    <div>
      <Table
        className="buttons-table"
        data={{
          headings,
          body: rows,
        }}
        rowHeight={58}
      />
    </div>
  );
}
