import React, { Component } from "react";
import MoreOptionsMenu from "../../ui/more-options-menu";
import MenuItems from "../menu-items";

export default class RowOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      licenseMenuOpen: false,
    };
  }

  handleMenuClose = () => {
    this.setState({
      licenseMenuOpen: false,
    });
  };

  handleRequestCertificationClick = () => {
    const { setEmailTemplate, setTeachersToEmail, teacher } = this.props;
    setTeachersToEmail([teacher.id]);
    setEmailTemplate("CERTIFICATION");
  };

  getMenuItems = () => {
    const { licenseMenuOpen } = this.state;
    const {
      hasDomainLicense,
      isApplyToAllLicense,
      isBookCreatorUser,
      featureFlags,
      setLicensesToAssign,
      setLicensesToRemove,
      teacher,
      licenseInfo,
      role,
      setTeachersToRemove,
      setTeachersToEmail,
      setUsersToDowngrade,
      setViewProfile,
      showViewProfile,
      toggleLibraryManager,
    } = this.props;
    return (
      <MenuItems
        hasDomainLicense={hasDomainLicense}
        isApplyToAllLicense={isApplyToAllLicense}
        isBookCreatorUser={isBookCreatorUser}
        licenseInfo={licenseInfo}
        teacher={teacher}
        showLicenseSubMenu={licenseMenuOpen}
        onLicenseParentClick={e => this.setState({ licenseMenuOpen: true })}
        onLicenseItemClick={license =>
          setLicensesToAssign({
            license: license,
            userIds: [teacher.id],
          })
        }
        role={role}
        featureFlags={featureFlags}
        showViewProfile={showViewProfile}
        onViewProfileClick={e => setViewProfile(teacher)}
        onSendWelcomeEmailClick={e => setTeachersToEmail([teacher.id])}
        onRemoveLicenseClick={e => setLicensesToRemove([teacher.id])}
        onRemoveTeacherClick={e => setTeachersToRemove([teacher.id])}
        onRequestCertificationClick={this.handleRequestCertificationClick}
        onSetUsersToDowngrade={e => setUsersToDowngrade([teacher.id])}
        onAddLibraryManagerRoleClick={() => {
          toggleLibraryManager({ id: teacher.id, value: true });
        }}
        onRemoveLibraryManagerRoleClick={() => {
          toggleLibraryManager({ id: teacher.id, value: false });
        }}
      />
    );
  };

  render() {
    return (
      <MoreOptionsMenu onClose={this.handleMenuClose} textonly width={240}>
        {this.getMenuItems()}
      </MoreOptionsMenu>
    );
  }
}
