const template = `You have been selected to become a Book Creator Certified Author! To get started, please visit https://app.bookcreator.com/certification. You can access the course at any time from the Certification tab of your Teacher Dashboard in Book Creator.

Good news! This course should only take about 1 hour, but is self-paced so no need to complete in one sitting. Take your time because the course finishes with a quiz. By the end you will be completely familiar with the features of Book Creator and be ready to take new ideas back to your classroom.

Please complete by {date}.

Once completed, you will receive accreditation as a Book Creator Certified Author, with a digital badge you can display on your online profiles.

Best of luck and happy book making!!

{admin}`;

export default template;
