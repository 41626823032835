import React from "react";
import Avatar from "../../../../avatar";
import BookshelfIcon from "src/images/svg/book-shelf.svg";
import SvgIcon from "../../../../ui/svg-icon";
import LibraryProperty from "../property";
import "./index.scss";
import { LibProps } from "../../types";

interface LibraryNameProps {
  isOwner: boolean;
  getAvatarUrl?: (userId: string) => {};
}

function LibraryName({
  isOwner,
  owner,
  name,
  getAvatarUrl,
}: LibraryNameProps & Partial<LibProps>) {
  return (
    <div className="library-menu-item">
      <div className="library-menu-item__icon">
        {isOwner || !owner ? (
          <SvgIcon icon={BookshelfIcon} />
        ) : (
          <Avatar
            name={owner.displayName}
            url={getAvatarUrl(owner.userId)}
            size={40}
          />
        )}
      </div>
      <LibraryProperty name="name">{name}</LibraryProperty>
    </div>
  );
}

export default LibraryName;
