import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Loader from "../../ui/loader";
import "./index.scss";

class FormButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicks: [],
    };
  }

  componentWillMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClick(e) {
    if (this.props.onClick) {
      this.props.onClick();
    }
    const { layerX, layerY } = e.nativeEvent;
    let clicks = this.state.clicks.slice();
    clicks.push({ x: layerX, y: layerY });
    this.setState(
      {
        clicks: clicks,
      },
      () => {
        setTimeout(() => {
          let clicks = this.state.clicks.slice();
          clicks.splice(0, 1);
          if (this._isMounted) {
            this.setState({
              clicks: clicks,
            });
          }
        }, 1000);
      }
    );
  }

  render() {
    const { type, text, href, flat, loading, disabled, minWidth } = this.props;

    const { clicks } = this.state;

    const waves = clicks.map((click, i) => {
      return (
        <div
          className="form-button__wave"
          key={i}
          style={{ margin: `${click.y}px 0 0 ${click.x}px` }}
        />
      );
    });

    const buttonClass = classNames("form-button", {
      "form-button--flat": flat,
    });

    const buttonStyle = {
      minWidth,
    };

    if (href) {
      return (
        <Link to={href} className={buttonClass}>
          {text}
        </Link>
      );
    } else {
      return (
        <button
          type={type ? type : "button"}
          className={buttonClass}
          style={buttonStyle}
          disabled={loading || disabled}
          onClick={this.handleClick.bind(this)}>
          {loading ? <Loader tiny /> : text}
          {waves}
        </button>
      );
    }
  }
}

export default FormButton;
