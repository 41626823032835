// Usage UUID.create()
export const UUID = {
  _rng: (function () {
    // From: https://github.com/kelektiv/node-uuid/blob/master/lib/rng-browser.js
    if (!process.browser) {
      return;
    }
    const crypto = window.crypto || window.msCrypto; // for IE 11
    if (crypto && crypto.getRandomValues) {
      // WHATWG crypto-based RNG - http://wiki.whatwg.org/wiki/Crypto
      // Moderately fast, high quality
      const _rnds8 = new Uint8Array(16);
      return function whatwgRNG() {
        crypto.getRandomValues(_rnds8);
        return _rnds8;
      };
    }

    // Math.random()-based (RNG)
    //
    // If all else fails, use Math.random().  It's fast, but is of unspecified
    // quality.
    const _rnds = new Array(16);
    return function () {
      for (var i = 0, r; i < 16; i++) {
        if ((i & 0x03) === 0) r = Math.random() * 0x100000000;
        _rnds[i] = (r >>> ((i & 0x03) << 3)) & 0xff;
      }

      return _rnds;
    };
  })(),
  create: function () {
    if (!process.browser) {
      return;
    }
    // From: https://github.com/kelektiv/node-uuid/blob/master/uuid.js
    const rnds = this._rng();

    // Per 4.4, set bits for version and `clock_seq_hi_and_reserved`
    rnds[6] = (rnds[6] & 0x0f) | 0x40;
    rnds[8] = (rnds[8] & 0x3f) | 0x80;

    // Base64 always 24 characters, with the last 2 being padding ==
    let base64 = btoa(String.fromCharCode.apply(null, rnds));
    // Remove the padding
    base64 = base64.slice(0, -2);
    /// Replace unsafe URL characters - see https://en.wikipedia.org/wiki/Base64#URL_applications
    base64 = base64.replace(/\//g, "_");
    base64 = base64.replace(/\+/g, "-");
    return base64;
  },
};
