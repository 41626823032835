import React, { forwardRef, useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { UUID } from "../../../utilities/string";
import PropTypes from "prop-types";
import classNames from "classnames";
import SvgIcon from "../../SvgIcon";
import CrossIcon from "../../../../images/svg/cross.svg";
import TickIcon from "../../../../images/svg/tick--circle.svg";
import Label from "../Label";
import Error from "../Error";
import "./index.scss";

const TextBox = forwardRef((props, ref) => {
  const {
    autocomplete,
    autocompleteOpen,
    autoFocus,
    children,
    clearable,
    complete,
    disabled,
    error,
    hiddenLabel,
    id,
    inputStyle,
    jumbo,
    label,
    large,
    minHeight,
    maxHeight,
    maxLength,
    maxLengthCounter,
    multiline,
    onBlur,
    onChange,
    onClear,
    onFocus,
    onKeyDown,
    optional,
    placeholder,
    portal,
    strings,
    style,
    tabIndex,
    type,
    value,
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [innerId, setInnerId] = useState(null);
  const [plainTextPassword, setPlainTextPassword] = useState("");
  const internalRef = useRef();
  const innerRef = ref || internalRef;

  useEffect(() => {
    setInnerId(id ? id : UUID.create());
  }, [id]);

  useEffect(() => {
    if (id) {
      setInnerId(id);
    }
  }, [id]);

  useEffect(() => {
    if (innerRef.current && autoFocus) {
      innerRef.current.focus();
    }
  }, [autoFocus, innerRef]);

  const handleFocus = () => {
    setIsFocused(true);
    if (onFocus) onFocus();
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (onBlur) onBlur();
  };

  const handleChange = evt => {
    if (onChange) {
      onChange(evt.target.value);
    }
  };

  const handleClearClick = evt => {
    onChange("");
    innerRef.current.focus();
    if (onClear) onClear();
    evt.preventDefault();
  };

  const togglePlainTextPassword = () => {
    setPlainTextPassword(!plainTextPassword);
  };

  const className = classNames("text-box-v2", {
    "text-box-v2--error": !!error,
    "text-box-v2--focus": isFocused && !disabled,
    "text-box-v2--large": large,
    "text-box-v2--jumbo": jumbo,
    "text-box-v2--multiline": multiline,
    "text-box-v2--hidden-label": !!hiddenLabel,
    "text-box-v2--complete": complete,
    "text-box-v2--disabled": disabled,
  });

  const getType = () => {
    if (type === "password" && plainTextPassword) {
      return "text";
    } else if (type) {
      return type;
    } else if (!multiline) {
      return "text";
    } else {
      return null;
    }
  };

  const textProps = {
    className: "text-box-v2__input",
    type: getType(),
    disabled,
    onChange: handleChange,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onKeyDown,
    onMouseDown: evt => evt.stopPropagation(),
    placeholder,
    id: innerId,
    maxLength,
    value,
    tabIndex,
    ref: innerRef,
    autoComplete: autocomplete ? "off" : null,
    "aria-expanded": autocompleteOpen ? true : null,
    "aria-autocomplete": autocomplete ? "list" : null,
    "aria-labelledby": `${innerId}-label ${error ? `${innerId}-error` : ``}`,
    "aria-owns": autocomplete ? `${innerId}-listbox` : null,
    role: autocomplete ? "combobox" : null,
    style: { minHeight, maxHeight, ...inputStyle },
  };

  return (
    <div className={className} style={style}>
      {label || hiddenLabel ? (
        <Label hidden={hiddenLabel} id={`${innerId}-label`} htmlFor={innerId}>
          {label || hiddenLabel}
          {optional ? <small>(optional)</small> : null}
        </Label>
      ) : null}
      <div className="text-box-v2__input-container">
        {multiline ? <textarea {...textProps} /> : <input {...textProps} />}
        {clearable && value && value.length > 0 ? (
          <button
            className="text-box-v2__clear"
            onClick={handleClearClick}
            type="button">
            <SvgIcon icon={CrossIcon} name="cross" className="textbox-clear" />
          </button>
        ) : null}
        {type === "password" ? (
          <button
            onClick={togglePlainTextPassword}
            type="button"
            title={
              plainTextPassword ? strings.hidePassword : strings.showPassword
            }
            className="text-box-v2__password-toggle">
            {plainTextPassword ? strings.hide : strings.show}
          </button>
        ) : null}
        {complete ? <SvgIcon icon={TickIcon} name="autocomplete-tick" /> : null}
        {portal
          ? ReactDOM.createPortal(children, document.querySelector(portal))
          : children}
        {error ? <Error id={`${innerId}-error`}>{error}</Error> : null}
      </div>
      {maxLength && maxLengthCounter ? (
        <span className="text-box-v2__counter">
          {value.length}/{maxLength}
        </span>
      ) : null}
    </div>
  );
});

TextBox.propTypes = {
  autocomplete: PropTypes.bool,
  autocompleteOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  clearable: PropTypes.bool,
  complete: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  hiddenLabel: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  large: PropTypes.bool,
  minHeight: PropTypes.number,
  maxHeight: PropTypes.number,
  maxLength: PropTypes.number,
  maxLengthCounter: PropTypes.bool,
  multiline: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  optional: PropTypes.bool,
  placeholder: PropTypes.string,
  portal: PropTypes.string,
  strings: PropTypes.object,
  style: PropTypes.object,
  tabIndex: PropTypes.number,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default TextBox;
