import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  getIsButtonConsumer,
  getOrganisationId,
} from "../../../core/organisation";
import { notificationsActions } from "../../../core/notifications";
import ApiKeysPage from "./";
import { getEmailVerified } from "../../../core/auth";

function ApiKeysPageContainer(props) {
  return <ApiKeysPage {...props} />;
}

const mapDispatchToProps = {
  ...notificationsActions,
};

const mapStateToProps = createSelector(
  getOrganisationId,
  getEmailVerified,
  getIsButtonConsumer,
  (organisationId, verified, isButtonConsumer) => ({
    organisationId,
    verified,
    isButtonConsumer,
  })
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiKeysPageContainer);
