import React from "react";
import "./index.scss";

function Article({ children }) {
  return <div className="article">{children}</div>;
}

export function H1({ children }) {
  return <h1 className="article__h1">{children}</h1>;
}

export function H2({ children }) {
  return <h2 className="article__h2">{children}</h2>;
}

export function H3({ children }) {
  return <h3 className="article__h3">{children}</h3>;
}

export function H4({ children }) {
  return <h4 className="article__h4">{children}</h4>;
}

export function DL({ children }) {
  return <dl className="article__dl">{children}</dl>;
}

export default Article;
