import React, { useState } from "react";
import { BUTTON_DOMAIN } from "../../../../config";
import { isValidDomain } from "../../../../utils/validators";
import MultilineImport from "../../multiline-import";

export default function AddApiKey(props) {
  const {
    onClose,
    organisationId,
    onSubmit,
    addErrorMessage,
    keyToEdit,
  } = props;

  const { authorisedDomains, apiKey } = keyToEdit;

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async domains => {
    setSubmitting(true);
    try {
      const response = await fetch(
        `${BUTTON_DOMAIN}/admin/${organisationId}/api-keys/${apiKey}`,
        {
          credentials: "include",
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ authorisedDomains: domains }),
        }
      );
      setSubmitting(false);
      if (response.status === 200) {
        const key = await response.json();
        onSubmit(key);
        onClose();
        return;
      } else if (response.status === 403) {
        addErrorMessage("They key has been revoked");
        return;
      }
      throw new Error();
    } catch (err) {
      addErrorMessage("Your key could not be edited.");
      setSubmitting(false);
    }
  };

  return (
    <MultilineImport
      title="Edit API key domains"
      intro="Enter the domains you will be using the API keys with"
      validator={input => input && isValidDomain(input, true)}
      validSingleMessage="%0 domain ready to add"
      validMultipleMessage="%0 domains ready to add"
      invalidSingleMessage="%0 invalid domain"
      invalidMultipleMessage="%0 invalid domains"
      loading={submitting}
      onClose={onClose}
      initialValue={authorisedDomains}
      onSubmit={handleSubmit}
      submitLabel="Update key"
    />
  );
}
