export const Tabs = {
  ALL: 0,
  ARCHIVED: 1,
} as const;

export const TabsLabels = {
  [Tabs.ALL]: "All libraries",
  [Tabs.ARCHIVED]: "Archived Libraries",
};

export const AllLibsColumns = [
  {
    name: "Library Name",
    sortable: "name",
    width: 185,
  },
  {
    name: "Role",
    sortable: "role",
    width: 120,
  },
  {
    name: "Books",
    sortable: "books",
    width: 120,
  },
  {
    name: "Authors",
    sortable: "author",
    width: 120,
  },
  {
    name: "",
    width: 165,
  },
];

export const ArchivedLibsColumns = [
  {
    name: "Library Name",
    sortable: "name",
  },
  {
    name: "",
    width: 10,
  },
];

export const LibraryRoleLabel = {
  OWNER: "Owner",
  ADMIN: "Co-Teacher",
  TEACHER: "Teacher",
  STUDENT: "Student",
  READ_ONLY: "Read-Only",
};

export const LibraryRole = {
  OWNER: 1,
  PREVIOUS_OWNER: 99,
  MANAGER: 400,
  ADMIN: 400,
  TEACHER: 600,
  STUDENT: 800,
  READ_ONLY: 900,
} as const;

export const OrganisationRole = {
  PENDING_TEACHER: -600,
  MANAGER: 400,
  TEACHER: 600,
} as const;
