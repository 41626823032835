import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

function FormError(props) {
  const { children, id } = props;

  return (
    <span className="form-error" id={id}>
      {children}
    </span>
  );
}

FormError.propTypes = {
  children: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default FormError;
