import React from "react";
import classNames from "classnames";
import LockIcon from "src/images/svg/lock.svg";
import ExternalLinkIcon from "src/images/svg/external-link-new.svg";

import SvgIcon from "../../../../ui/svg-icon";
import "./index.scss";

interface ILibraryProperty {
  name: string;
  isPrivate?: boolean;
  children?: any;
  disabled?: boolean;
  onClickHandler?: (e) => void;
}

function LibraryProperty({
  name,
  children,
  isPrivate,
  disabled = false,
  onClickHandler,
}: ILibraryProperty) {
  const cssClass = classNames("library-property", {
    [`library-property__${name}`]: true,
    [`library-property__${name}_link`]:
      onClickHandler && !isPrivate && !disabled,
    [`library-property__disabled`]: disabled,
  });

  const getContent = () => {
    if (isPrivate) {
      return (
        <div className={cssClass}>
          <span>Private</span>
          <SvgIcon icon={LockIcon} />
        </div>
      );
    } else if (onClickHandler) {
      return (
        <div className={cssClass}>
          <span onClick={!disabled ? onClickHandler : null}>Visit Library</span>
          <SvgIcon icon={ExternalLinkIcon} />
        </div>
      );
    }
    return <div className={cssClass}>{children}</div>;
  };

  return getContent();
}

export default LibraryProperty;
