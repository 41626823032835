const template = `Great news! You have been invited to join our organization to access your premium Book Creator account. To get started, please sign in at https://app.bookcreator.com/teacher. If you have not created an account yet, simply answer the prompted questions. Next, accept your invitation to join our organization. 
{license}      
Please activate your account by {date}.

Wondering how to implement Book Creator into your curriculum? Here are 3 ways to get started:

1: Take the 1 hour Book Creator Certified Author course at https://app.bookcreator.com/certification
2: Explore teacher resources by subject and grade level at https://bookcreator.com/resources-for-teachers/
3: Register for an upcoming webinar at https://bookcreator.com/webinars/

If you have any questions, please explore Book Creator's support page: https://intercom.help/bookcreator/en/articles/4149394-getting-started-overview.

Happy book making!

{admin}`;

export default template;
