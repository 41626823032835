import { createSelector } from "reselect";

export function getOrganisationState(state) {
  return state.organisation;
}

export function getOrganisationLoaded(state) {
  return getOrganisationState(state).loaded;
}

export function getOrganisation(state) {
  return getOrganisationState(state).organisation;
}

export const getOrganisationJS = createSelector(
  getOrganisation,
  organisation => {
    return organisation && organisation.toJS
      ? organisation.toJS()
      : organisation;
  }
);

export const getOrganisationId = createSelector(
  getOrganisationJS,
  organisation => {
    return organisation?.id;
  }
);

export const getIsButtonConsumer = createSelector(
  getOrganisationJS,
  organisation => {
    return !!organisation?.buttonAPIEnabled;
  }
);
export function getOrganisationList(state) {
  const orgs = getOrganisationState(state).organisationList;
  return orgs.toJS ? orgs.toJS() : orgs;
}

export function getOrganisationListLoaded(state) {
  return getOrganisationState(state).organisationListLoaded;
}

export function getOrganisationListLoading(state) {
  return getOrganisationState(state).organisationListLoading;
}

export const getOrganisationCount = createSelector(
  getOrganisationList,
  orgs => {
    return orgs.length;
  }
);

export function getLicenseInfo(state) {
  return getOrganisation(state) ? getOrganisation(state).get("licenses") : null;
}

export const getLicenseInfoJS = createSelector(getLicenseInfo, licenseInfo => {
  return licenseInfo && licenseInfo.toJS ? licenseInfo.toJS() : licenseInfo;
});

export const getLicensesLoading = createSelector(
  getOrganisationState,
  state => {
    return state.licensesLoading;
  }
);

export const getLicenseError = createSelector(getOrganisationState, state => {
  return state.licenseError;
});

export const getTotalLicenseCount = createSelector(getLicenseInfo, licenses => {
  let count = 0;
  if (!licenses) {
    return count;
  }
  licenses.forEach(license => {
    count += license.quantity;
  });
  return count;
});

export const getTotalAssignedLicenseCount = createSelector(
  getLicenseInfo,
  licenses => {
    let count = 0;
    if (!licenses) {
      return count;
    }
    licenses.forEach(license => {
      count += license.allocated;
    });
    return count;
  }
);

export function getTotalAvailableLicenseCount(state) {
  let count = 0;
  const licenses = getLicenseInfo(state) || [];
  licenses.forEach(license => {
    count += license.quantity - license.allocated;
  });
  return count;
}

export const getLicenseTotals = createSelector(
  getTotalLicenseCount,
  getTotalAssignedLicenseCount,
  getTotalAvailableLicenseCount,
  (
    totalLicenseCount,
    totalAssignedLicenseCount,
    totalAvailableLicenseCount
  ) => {
    return {
      totalLicenseCount,
      totalAssignedLicenseCount,
      totalAvailableLicenseCount,
    };
  }
);

export const getHasDomainLicense = createSelector(
  getOrganisationJS,
  organisation => {
    return !!organisation?.isDomain;
  }
);

export const getIsApplyToAllLicense = createSelector(
  getLicenseInfoJS,
  licenses => {
    return !!(
      licenses?.length && licenses.every(license => license.applyToAll)
    );
  }
);

export const getShowOrgPicker = createSelector(getOrganisationState, state => {
  return !!state.showOrgPicker;
});

export const getOrganisationFeatureFlags = createSelector(
  getOrganisationJS,
  organisation => {
    return organisation?.features;
  }
);

export const getHasOrgLibraries = createSelector(
  getOrganisationFeatureFlags,
  flags => {
    return !!flags?.orgLibraries;
  }
);
