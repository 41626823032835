import { createSelector } from "reselect";

export function getAuth(state) {
  return state.auth;
}

export const isAuthenticated = createSelector(getAuth, auth => {
  return auth.authenticated;
});

export const getUserId = createSelector(getAuth, auth => {
  return auth.id;
});

export const getAuthToken = createSelector(getAuth, auth => {
  return auth.authToken;
});

export const getEmail = createSelector(getAuth, auth => {
  return auth.email;
});

export const getIsBookCreatorUser = createSelector(getEmail, email => {
  return !!(email && email.indexOf("@bookcreator.com") > -1);
});

export const getDisplayName = createSelector(getAuth, auth => {
  return auth.displayName;
});

export const getEmailVerified = createSelector(getAuth, auth => {
  return auth.verified;
});

export const getEmailUnverified = createSelector(getAuth, auth => {
  return auth.unverified;
});
