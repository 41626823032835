import React, { useState, useRef } from "react";
import SvgIcon from "../ui/svg-icon";
import ClipboardIcon from "../../../images/svg/clipboard.svg";
import "./index.scss";

export default function CopyToClipboard(props) {
  const { text, style } = props;

  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const timeoutRef = useRef();

  const handleClick = evt => {
    clearTimeout(timeoutRef.current);
    navigator.clipboard.writeText(text);
    setShowCopiedMessage(true);
    timeoutRef.current = setTimeout(() => {
      setShowCopiedMessage(false);
    }, 1000);
  };

  return (
    <button onClick={handleClick} className="copy-to-clipboard" style={style}>
      {showCopiedMessage ? (
        <span className="copy-to-clipboard__copied">Copied</span>
      ) : null}
      <SvgIcon icon={ClipboardIcon} />
    </button>
  );
}
