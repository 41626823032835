import React, { Component } from "react";
import { getInitialsFromName } from "../../../utils";
import StarIcon from "../../../images/svg/star.svg";
import "./index.scss";
import classNames from "classnames";
import SvgIcon from "../ui/svg-icon";
import ReactTooltip from "react-tooltip";

export default class Avatar extends Component {
  state = {
    imageFailed: false,
    imageUrl: null,
  };

  componentWillMount() {
    this._isMounted = true;
    const { url } = this.props;
    if (url) {
      this.loadImage(url);
    }
  }

  loadImage = url => {
    const image = new Image();
    image.src = url;
    image.onload = e => {
      if (this._isMounted) {
        this.setState({ imageUrl: url, imageFailed: false });
      }
    };
    image.onerror = e => {
      if (this._isMounted) {
        this.setState({ imageFailed: true });
      }
    };
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.url !== this.props.url) {
      this.loadImage(nextProps.url);
    }
  }

  componentDidUpdate() {
    const { url } = this.props;
    const { imageUrl } = this.state;
    if (imageUrl && !url) {
      this.setState({ imageUrl: null });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { id, name, size, overlayIcon, isLibraryManager } = this.props;
    const { imageFailed, imageUrl } = this.state;

    let avatarIcon;

    const style = {
      width: size,
      height: size,
    };

    const initialsScale = size ? size / 40 : 1;

    const initialsStyle = {
      transform: size ? `scale(${initialsScale})` : null,
    };

    if (imageUrl && !imageFailed) {
      avatarIcon = (
        <img
          src={`${imageUrl}`}
          onError={e => {
            this.setState({ imageFailed: true });
          }}
          alt={name}
        />
      );
    } else {
      avatarIcon = (
        <span className="avatar__initials" style={initialsStyle}>
          {getInitialsFromName(name)}
        </span>
      );
    }

    const className = classNames("avatar", {
      "avatar--library-manager": isLibraryManager,
    });

    const badgeClassName = classNames("avatar__badge", {
      "avatar__badge--library-manager": isLibraryManager,
    });

    const tooltipId = isLibraryManager ? `tooltip-${id}` : null;

    return (
      <div className="avatar-container" data-tip data-for={tooltipId}>
        <div className={className} style={style}>
          {avatarIcon}
          {overlayIcon ? (
            <div className="overlay">
              <div className="icon">{overlayIcon}</div>
            </div>
          ) : null}
        </div>
        {isLibraryManager ? (
          <div className={badgeClassName}>
            <SvgIcon
              icon={StarIcon}
              name="star"
              className="avatar__badge-icon"
            />
          </div>
        ) : null}
        {tooltipId ? (
          <ReactTooltip id={tooltipId} effect="solid" multiline={false}>
            Library Manager
          </ReactTooltip>
        ) : null}
      </div>
    );
  }
}
