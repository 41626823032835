import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import Header, { HeaderActions } from "../../components/header";
import HeaderButton from "../../components/button/header";
import Content from "../../components/content";
import Loader from "../../../common/components/Loader";
import EmptyState from "../../components/empty-state";
import AddApiKey from "../../components/api-keys/add";
import EditApiKey from "../../components/api-keys/edit";
import APIKeysTable from "../../components/api-keys/table";
import Revoke from "../../components/api-keys/revoke";
import { BUTTON_DOMAIN } from "../../../config";
import { Redirect } from "react-router";

function ApiKeysPage(props) {
  const { isButtonConsumer, organisationId, addErrorMessage, verified } = props;
  const [apiKeys, setApiKeys] = useState(null);
  const [showAddApiKey, setShowAddApiKey] = useState(false);
  const [keyToRevoke, setKeyToRevoke] = useState(null);
  const [keyToEdit, setKeyToEdit] = useState(null);

  useEffect(() => {
    const loadApiKeys = async () => {
      const response = await fetch(
        `${BUTTON_DOMAIN}/admin/${organisationId}/api-keys`,
        {
          credentials: "include",
        }
      );
      switch (response.status) {
        case 200:
          const keys = await response.json();
          setApiKeys(keys);
          break;
        default:
          setApiKeys([]);
          addErrorMessage(
            "API keys could not be loaded, please refresh and try again"
          );
          break;
      }
    };
    if (organisationId && verified && isButtonConsumer) {
      loadApiKeys();
    }
  }, [organisationId, addErrorMessage, verified, isButtonConsumer]);

  const handleAdd = key => {
    setApiKeys([...apiKeys, key]);
  };

  const handleRevokeSuccess = revokedKey => {
    let keys = apiKeys.map(key => {
      if (key.apiKey === revokedKey.apiKey) {
        return {
          ...key,
          revokedAt: new Date().toISOString(),
        };
      } else {
        return key;
      }
    });
    setApiKeys(keys);
  };

  const handleEditKey = updatedKey => {
    let keys = apiKeys.map(key => {
      if (key.apiKey === updatedKey.apiKey) {
        return updatedKey;
      } else {
        return key;
      }
    });
    setApiKeys(keys);
  };

  if (!isButtonConsumer) {
    return <Redirect to="/" />;
  }

  return (
    <div className="api-keys-page">
      <Helmet title="API Keys" />
      <Header title="API Keys">
        <HeaderActions right>
          <HeaderButton onClick={e => setShowAddApiKey(true)}>
            Add an API key
          </HeaderButton>
        </HeaderActions>
      </Header>
      <Content className="api-keys-page__content">
        {!apiKeys ? <Loader center color="white" /> : null}
        {apiKeys && apiKeys.length === 0 ? (
          <EmptyState
            title="You haven't created any API keys yet"
            body="They will be listed here when you have some"
          />
        ) : null}
        {apiKeys && apiKeys.length > 0 ? (
          <APIKeysTable
            apiKeys={apiKeys}
            setKeyToRevoke={setKeyToRevoke}
            setKeyToEdit={setKeyToEdit}
          />
        ) : null}
        {showAddApiKey ? (
          <AddApiKey
            addErrorMessage={addErrorMessage}
            onClose={() => setShowAddApiKey(false)}
            organisationId={organisationId}
            onAdd={handleAdd}
          />
        ) : null}
        {keyToRevoke ? (
          <Revoke
            keyToRevoke={keyToRevoke}
            organisationId={organisationId}
            onRevokeSuccess={handleRevokeSuccess}
            onClose={() => setKeyToRevoke(null)}
          />
        ) : null}
        {keyToEdit ? (
          <EditApiKey
            addErrorMessage={addErrorMessage}
            onClose={() => setKeyToEdit(null)}
            organisationId={organisationId}
            keyToEdit={keyToEdit}
            onSubmit={handleEditKey}
          />
        ) : null}
      </Content>
    </div>
  );
}

export default ApiKeysPage;
