import React from "react";
import MiniMenuItem from "../../../ui/mini-menu/item";
import MoreOptionsMenu from "../../../ui/more-options-menu";

export default function APIKeysTable(props) {
  const { button, setButtonToEdit, setButtonToDelete } = props;

  return (
    <MoreOptionsMenu textonly>
      <MiniMenuItem onClick={() => setButtonToEdit(button)}>
        Edit name
      </MiniMenuItem>
      <MiniMenuItem type="negative" onClick={() => setButtonToDelete(button)}>
        Delete
      </MiniMenuItem>
    </MoreOptionsMenu>
  );
}
