import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import Licenses from "../pages/licenses";
import Teachers from "../pages/teachers";
import Portfolios from "../pages/portfolios";
import Admin from "../pages/admin";
import Analytics from "../pages/analytics";
import Resources from "../pages/resources";
import LMS from "../pages/lms";
import AppManagementPage from "../pages/apps/container";
import Buttons from "../pages/buttons/container";
import APIKeysPage from "../pages/api-keys/container";
import ButtonSDKDocs from "../pages/docs/button-sdk";
import ImageSearchPage from "../pages/image-search";
import "./main.scss";

export default function Main(props) {
  const { url, path } = useRouteMatch();

  const { store, isButtonConsumer } = props;

  return (
    <div className="main">
      <Switch>
        <Route path={`${path}/teachers`} component={Teachers} store={store} />
        <Route
          path={`${path}/portfolios`}
          component={Portfolios}
          store={store}
        />
        <Route path={`${path}/admin`} component={Admin} store={store} />
        <Route path={`${path}/analytics`} component={Analytics} store={store} />
        <Route path={`${path}/resources`} component={Resources} store={store} />
        <Route path={`${path}/lms`} component={LMS} store={store} />
        <Route
          path={`${path}/apps`}
          component={AppManagementPage}
          store={store}
        />
        <Route
          path={`${path}/image-search`}
          component={ImageSearchPage}
          store={store}
        />
        <Route
          path={`${path}/api-keys`}
          component={APIKeysPage}
          store={store}
        />
        <Route path={`${path}/buttons`} component={Buttons} store={store} />
        <Route
          path="/docs/button-sdk"
          component={ButtonSDKDocs}
          store={store}
        />

        {isButtonConsumer ? (
          <Redirect from={`${path}`} to={`${url}/api-keys`} />
        ) : (
          <Route path={`${path}`} exact component={Licenses} store={store} />
        )}
        <Redirect from="*" to={`${url}`} />
      </Switch>
    </div>
  );
}
