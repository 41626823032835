import React, { Component } from "react";
import Immutable from "immutable";
import classNames from "classnames";
import SvgIcon from "../../components/ui/svg-icon";
import ChevronIcon from "../../../images/svg/chevron.svg";
import TriangleIcon from "../../../images/svg/triangle.svg";
import HelpIcon from "../../../images/svg/help--round.svg";
import Checkbox from "../forms/checkbox";
import Dropdown from "../forms/dropdown";
import ReactTooltip from "react-tooltip";
import { sortRows } from "./row-sort";
import "./index.scss";

const PAGE_SIZES = [10, 20, 50, 100];

const SortButtons = props => {
  const { onClick, heading, sortBy } = props;

  const sort = sortBy.split(".");
  const ascClasses = classNames(
    "table__heading-sort",
    "table__heading-sort--asc",
    {
      active: heading.sortable === sort[0] && sort[1] === "ASC",
    }
  );
  const descClasses = classNames(
    "table__heading-sort",
    "table__heading-sort--desc",
    {
      active: heading.sortable === sort[0] && sort[1] === "DESC",
    }
  );

  return (
    <div className="table__heading-controls">
      <button
        className={ascClasses}
        onClick={e => {
          onClick(`${heading.sortable}.ASC`);
          e.stopPropagation();
        }}>
        <SvgIcon icon={TriangleIcon} name="triangle" />
      </button>
      <button
        className={descClasses}
        onClick={e => {
          onClick(`${heading.sortable}.DESC`);
          e.stopPropagation();
        }}>
        <SvgIcon icon={TriangleIcon} name="triangle" />
      </button>
    </div>
  );
};

export default class Table extends Component {
  state = {
    pageSize: 10,
    currentPage: 0,
    rows: [],
  };

  componentDidMount = () => {
    this.updateRows();
  };

  updateRows = () => {
    this.setState({
      rows: this.getRows(this.props),
    });
  };

  getRows = props => {
    const { pageSize, currentPage } = this.state;
    const { sortBy } = props;
    const skip = pageSize * currentPage;
    let t = props.data.body;

    if (sortBy) {
      t = sortRows(t, sortBy);
    }

    t = t.skip(skip).take(pageSize);
    return t;
  };

  renderHeadings = () => {
    const { data, onHeadingClick, sortBy } = this.props;
    return data.headings.map((heading, i) => {
      const sort = sortBy ? sortBy.split(".") : null;
      const nextSortBy = sortBy
        ? sort[0] === heading.sortable
          ? sort[1] === "ASC"
            ? `${heading.sortable}.DESC`
            : `${heading.sortable}.ASC`
          : `${heading.sortable}.ASC`
        : null;
      return (
        <th
          className={heading.sortable ? "table__sortable-heading" : null}
          key={i}
          style={{
            width: heading.width ? heading.width : null,
            textAlign: heading.align,
          }}
          onClick={e => {
            onHeadingClick(nextSortBy);
            this.resetPages();
            this.deselectAll();
          }}>
          <span className="table__sortable-heading-name">
            {heading.name}
            {heading.tooltip ? (
              <span
                className="tooltip"
                onClick={evt => evt.stopPropagation()}
                data-tip
                data-for={`tooltip-${heading.name}`}>
                <SvgIcon icon={HelpIcon} name="help" />
                <ReactTooltip
                  id={`tooltip-${heading.name}`}
                  effect="solid"
                  clickable
                  html={true}
                  multiline>
                  {heading.tooltip}
                </ReactTooltip>
              </span>
            ) : null}
          </span>
          {heading.sortable ? (
            <SortButtons
              onClick={payload => {
                onHeadingClick(payload);
                this.resetPages();
                this.deselectAll();
              }}
              sortBy={sortBy}
              heading={heading}
            />
          ) : null}
        </th>
      );
    });
  };

  componentWillReceiveProps = nextProps => {
    this.setState({
      rows: this.getRows(nextProps),
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.onDeselectRows &&
      (prevState.pageSize > this.state.pageSize ||
        prevState.rows.size > this.state.rows.size)
    ) {
      const rowsToDeselect = [];
      this.props.selectedRows.forEach(selectedRow => {
        const i = this.state.rows.findIndex(row => {
          return row.rowId === selectedRow;
        });
        if (i < 0) {
          rowsToDeselect.push(selectedRow);
        }
      });
      this.props.onDeselectRows(rowsToDeselect);
    }
    const { onPageSizeChange, onRowsChange } = this.props;
    const { pageSize, rows } = this.state;
    if (onPageSizeChange && pageSize !== prevState.pageSize) {
      onPageSizeChange(pageSize);
    }
    if (onRowsChange && !Immutable.is(rows, prevState.rows)) {
      onRowsChange(rows.toJS ? rows.toJS() : rows);
    }
  };

  resetPages = () => {
    this.setState({
      currentPage: 0,
    });
  };

  renderBody = () => {
    const { rows } = this.state;
    const { selectable, rowHeight, data, onRowSelect } = this.props;
    return rows.map((row, i) => {
      const rowClass = classNames({
        "table__row--selected": row.selected,
        "table__row--warning": row.warning,
        "table__row--disabled": row.disabled,
        [`table__row--${row.align}`]: row.align,
        [`${row.className}`]: row.className,
      });
      return (
        <tr
          key={i}
          data-id={row.rowId}
          className={rowClass}
          style={{
            height: rowHeight,
          }}
          onClick={
            selectable && onRowSelect
              ? e => {
                  onRowSelect([row.rowId]);
                }
              : null
          }>
          {selectable ? (
            <td className="table__checker">
              <Checkbox checked={row.selected} />
            </td>
          ) : null}
          {row.cells.map((cell, j) => {
            const alignment = data.headings[j].align;
            return (
              <td key={j} style={{ textAlign: alignment }}>
                {cell}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  selectAll = () => {
    if (this.allSelected()) {
      this.deselectAll();
    } else {
      const ids = this.state.rows.map(row => {
        return row.rowId;
      });
      this.props.onSelectAll(ids);
    }
  };

  deselectAll = () => {
    const ids = this.state.rows.map(row => {
      return row.rowId;
    });
    this.props.onDeselectAll(ids);
  };

  allSelected = () => {
    const { selectedRows } = this.props;
    const { rows } = this.state;
    if (!selectedRows) {
      return false;
    }
    return selectedRows.size === rows.size;
  };

  nextPage = () => {
    this.setPage(this.state.currentPage + 1);
  };

  prevPage = () => {
    this.setPage(this.state.currentPage - 1);
  };

  setPage = nextPage => {
    const { resetSelection } = this.props;
    if (resetSelection) resetSelection();
    this.setState(
      {
        currentPage: nextPage,
      },
      this.updateRows
    );
  };

  setPageSize = value => {
    const { currentPage } = this.state;
    const nextPageSize = parseInt(value, 10);
    const { body } = this.props.data;
    const nextPageIndex =
      currentPage * nextPageSize > body.size ? 0 : currentPage;
    this.setState(
      {
        pageSize: nextPageSize,
        currentPage: nextPageIndex,
      },
      this.updateRows
    );
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { data, selectable, className } = this.props;
    const { body } = data;

    const morePagesToCome = body.size > pageSize * (currentPage + 1);
    const pageCount = Math.ceil(body.size / pageSize);

    const currentPageMax = Math.min((currentPage + 1) * pageSize, body.size);
    const currentRange = `${
      currentPage * pageSize + 1
    } - ${currentPageMax} of ${body.size}`;

    let pageOptions = [];
    for (let i = 0; i < pageCount; i++) {
      pageOptions.push({ value: i, label: i + 1 });
    }

    const cssClass = classNames("table", {
      "table--selectable": selectable,
      [className]: className,
    });

    return (
      <div className={cssClass}>
        <table>
          <thead>
            <tr>
              {selectable ? (
                <th className="table__checker">
                  <Checkbox
                    checked={this.allSelected()}
                    onClick={this.selectAll}
                  />
                </th>
              ) : null}
              {this.renderHeadings()}
            </tr>
          </thead>
          <tbody>{this.renderBody()}</tbody>
        </table>
        {body.size > 10 ? (
          <div className="table__controls">
            <span className="table__control-label">Page:</span>
            <div style={{ width: currentPage >= 9 ? 62 : 56 }}>
              <Dropdown
                value={pageOptions[currentPage]}
                triangle
                onChange={e => {
                  this.setPage(e.value);
                }}
                options={pageOptions}
              />
            </div>
            <span className="table__control-label">Rows per page:</span>
            <div style={{ width: pageSize > 99 ? 72 : 62 }}>
              <Dropdown
                value={{ value: pageSize, label: pageSize }}
                triangle
                onChange={e => {
                  this.setPageSize(e.value);
                }}
                options={PAGE_SIZES.map(pageSize => {
                  return { value: pageSize, label: pageSize };
                })}
              />
            </div>
            <span className="table__page-range">{currentRange}</span>
            <button
              className="table__previous"
              onClick={this.prevPage}
              disabled={currentPage === 0}>
              <span>
                <SvgIcon icon={ChevronIcon} name="chevron" />
              </span>
            </button>
            <button
              className="table__next"
              onClick={this.nextPage}
              disabled={!morePagesToCome}>
              <span>
                <SvgIcon icon={ChevronIcon} name="chevron" />
              </span>
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}
