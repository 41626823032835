import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./index.scss";

function Label(props) {
  const { children, hidden, htmlFor, id } = props;

  const className = classNames("input-label", {
    "visually-hidden": hidden,
  });

  return (
    <label className={className} id={id} htmlFor={htmlFor}>
      {children}
    </label>
  );
}

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  hidden: PropTypes.bool,
  htmlFor: PropTypes.string,
  id: PropTypes.string,
};

export default Label;
