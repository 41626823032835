import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { UserAgentApplication } from "msal";
import { OFFICE_365_CLIENT_ID } from "../../../../config";
import Helmet from "react-helmet";

import { authActions, getAuth } from "../../../../core/auth";
import Logo from "../../../../images/book-creator-logo-colour.png";
import BackgroundImage from "../../../../images/backgrounds/sign-in.png";
import Form from "../../forms/form";
import FormButton from "../../forms/form-button";
import Loader from "../../ui/loader";
import Message from "../../ui/message";

const graphAPIScopes = ["https://graph.microsoft.com/user.read"];

class Office365 extends Component {
  componentWillMount() {
    this.doOfficeAuth();
    this._timeout = setTimeout(() => {
      if (!this.props.auth.office365Error) {
      }
    }, 10000);
  }

  componentWillUnmount() {
    this.props.clearOffice365Error();
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
  }

  doOfficeAuth = () => {
    const redirectUri = `https://${window.location.host}/sign-in/office-365`;
    let _calledBack = false;

    let self = this;

    var userAgentApplication = new UserAgentApplication(
      OFFICE_365_CLIENT_ID,
      null,
      loginCallback,
      { redirectUri }
    );

    function loginCallback(errorDesc, token, error, tokenType) {
      _calledBack = true;
      userAgentApplication = this;
      if (error || errorDesc) {
        handleError(error || errorDesc);
      } else if (token) {
        getToken();
      }
    }

    if (userAgentApplication.redirectUri) {
      userAgentApplication.redirectUri = redirectUri;
    }
    if (
      !userAgentApplication.isCallback(window.location.hash) &&
      window.parent === window &&
      !window.opener
    ) {
      setTimeout(() => {
        if (!_calledBack) {
          getToken();
        } else {
          _calledBack = false;
        }
      }, 500);
    }

    function getToken() {
      const { doOffice365Login } = self.props;
      const user = userAgentApplication.getUser();
      if (!user) {
        userAgentApplication.loginRedirect(graphAPIScopes);
      } else {
        userAgentApplication.acquireTokenSilent(graphAPIScopes).then(
          token => {
            doOffice365Login({ token });
          },
          error => {
            if (error) {
              userAgentApplication.acquireTokenRedirect(graphAPIScopes);
            } else {
            }
          }
        );
      }
    }

    function handleError(error) {
      const errorMessage =
        error && error === "access_denied"
          ? "You need to allow access to your account to continue"
          : error;
      self.props.onOffice365Error(errorMessage);
    }
  };

  retry = e => {
    this.props.clearOffice365Error();
    this.doOfficeAuth();
    e.preventDefault();
  };

  render() {
    const { auth } = this.props;
    const { office365Error } = auth;

    const containerStyle = {
      backgroundImage: `url(${BackgroundImage})`,
    };

    return (
      <div className="sign-in" style={containerStyle}>
        <Helmet title="Book Creator - Office365 login" />
        <div className="sign-in__content">
          <h1 className="sign-in__logo">
            <img src={Logo} alt="Book Creator" />
          </h1>
          {office365Error ? (
            <div>
              <Form header="Sign in with Office 365" onSubmit={this.retry}>
                <div className="form__content">
                  <Message type="error">{office365Error}</Message>
                </div>
                <div className="form__actions">
                  <FormButton flat href="/sign-in" text="Cancel" />
                  <FormButton type="submit" text="Try again" />
                </div>
              </Form>
            </div>
          ) : (
            <Loader colour="white" />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = createSelector(getAuth, auth => ({
  auth,
}));

const mapDispatchToProps = { ...authActions };

export default connect(mapStateToProps, mapDispatchToProps)(Office365);
