import React, { useState } from "react";
import { BUTTON_DOMAIN } from "../../../../config";
import ApiKey from "../../api-key";
import Modal from "../../../../common/components/Modal";

export default function ButtonToDelete(props) {
  const [deleting, setDeleting] = useState(false);

  const {
    addErrorMessage,
    buttonToDelete,
    onClose,
    organisationId,
    onDeleteSuccess,
  } = props;

  const handleDeleteButton = async () => {
    setDeleting(true);
    try {
      const response = await fetch(
        `${BUTTON_DOMAIN}/admin/${organisationId}/buttons/${buttonToDelete.id}`,
        {
          credentials: "include",
          method: "DELETE",
        }
      );
      setDeleting(false);
      switch (response.status) {
        case 204:
        case 409:
          onDeleteSuccess(buttonToDelete);
          onClose();
          break;
        default:
          throw new Error();
      }
    } catch (err) {
      addErrorMessage(
        "Button could not be deleted, please refresh and try again"
      );
    }
  };

  return (
    <Modal
      title="Delete button?"
      onSubmit={handleDeleteButton}
      submitLabel="Delete"
      submitType="negative"
      submitLoading={deleting}
      onClose={onClose}>
      <div style={{ padding: "20px 30px" }}>
        Are you sure you want to delete the button{" "}
        <ApiKey>{buttonToDelete.id}</ApiKey>
      </div>
    </Modal>
  );
}
