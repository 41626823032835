import React from "react";
import MultilineImport, { IRecord } from "../multiline-import";
import { isValidEmail } from "../../../utils/validators";

interface IProps {
  title: string;
  userType: "teachers" | "admins";
  importing: boolean;
  assignLicenses?: boolean;
  onSubmit(records: IRecord[]): void;
  onClose();
}

export default function EmailImport(props: IProps) {
  const {
    title,
    userType,
    importing,
    assignLicenses = false,
    onSubmit,
    onClose,
  } = props;

  const userTypeSingle = userType === "teachers" ? "teacher" : "admin";
  return (
    <MultilineImport
      title={title}
      intro={`Enter the emails of the ${userType} you’d like to import, with each
        individual email on a new line.`}
      validator={input => input && isValidEmail(input)}
      validSingleMessage={`%0 ${userTypeSingle} is ready to import`}
      validMultipleMessage={`%0 ${userType} are ready to import`}
      invalidSingleMessage="%0 invalid email address"
      invalidMultipleMessage="%0 invalid email addresses"
      onSubmit={onSubmit}
      loading={importing}
      assignLicenses={assignLicenses}
      submitLabel="Import"
      onClose={onClose}
    />
  );
}
