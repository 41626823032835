import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { getOrganisationId } from "../../../core/organisation";
import { getUserId } from "../../../core/auth";
import AppsPage from "./";

function AppsPageContainer(props) {
  return <AppsPage {...props} />;
}

const mapStateToProps = createSelector(
  getOrganisationId,
  getUserId,
  (organisationId, userId) => ({
    organisationId,
    userId,
  })
);

export default connect(mapStateToProps, null)(AppsPageContainer);
