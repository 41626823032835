export const RECEIVE_TEACHER_DATA = "RECEIVE_TEACHER_DATA";
export const ADD_TEACHERS = "ADD_TEACHER";
export const REMOVE_TEACHERS = "REMOVE_TEACHERS";
export const SET_SORT_BY = "SET_SORT_BY";
export const TOGGLE_TEACHER_SELECTION = "TOGGLE_TEACHER_SELECTION";
export const SELECT_TEACHERS = "SELECT_TEACHERS";
export const DESELECT_TEACHERS = "DESELECT_TEACHERS";
export const DESELECT_ALL_TEACHERS = "DESELECT_ALL_TEACHERS";
export const RESET_TEACHERS_STORE = "RESET_TEACHERS_STORE";
export const SET_CONFIRM_ASSIGN_LICENSES = "SET_CONFIRM_ASSIGN_LICENSES";
export const SET_ASSIGNING_LICENSES = "SET_ASSIGNING_LICENSES";
export const SET_CONFIRM_REMOVE_LICENSES = "SET_CONFIRM_REMOVE_LICENSES";
export const SET_REMOVING_LICENSES = "SET_REMOVING_LICENSES";
export const SET_SHOW_TEACHER_IMPORT = "SET_SHOW_TEACHER_IMPORT";
export const SET_IMPORTING_TEACHERS = "SET_IMPORTING_TEACHERS";
export const SET_TEACHERS_TO_REMOVE = "SET_TEACHERS_TO_REMOVE";
export const SET_REMOVING_TEACHERS = "SET_REMOVING_TEACHERS";
export const SET_TEACHERS_TO_EMAIL = "SET_TEACHERS_TO_EMAIL";
export const SET_EMAILING_TEACHERS = "SET_EMAILING_TEACHERS";
export const SET_EMAIL_SENT = "SET_EMAIL_SENT";
export const SET_EMAIL_TEMPLATE = "SET_EMAIL_TEMPLATE";
export const SET_USERS_TO_DOWNGRADE = "SET_USERS_TO_DOWNGRADE";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_DOWNGRADING_USERS = "SET_DOWNGRADING_USERS";
export const DOWNGRADE_USERS_SUCCESS = "DOWNGRADE_USERS_SUCCESS";
