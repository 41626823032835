import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  TIMES,
  TIME_TOGGLE_OPTIONS,
  USER_TOGGLE_OPTIONS,
  USER_TYPES,
} from "../../../pages/analytics/constants";
import { Chart as GAChart } from "react-google-charts";
import classNames from "classnames";
import EmptyState from "./empty-state";
import Loader from "../../ui/loader";
import Toggle from "../../forms/toggle";
import { defaultRenderer } from "../charts/data-renderers";
import "./index.scss";
import { useCustomAnalyticsApi, useDbAnalyticsApi } from "./api-hooks";
const PIE_CHART_HEIGHT = 300;
const LINE_CHART_HEIGHT = 400;

function Chart(props) {
  const {
    chartType,
    halfWidth,
    isStacked,
    legend,
    min,
    dataRenderer,
    endpoint,
    legendToggle,
    organisationId,
    rowFormatter,
    timeFilter,
    defaultTimeDimension,
    title,
    userFilter,
    isCustomAnalytics,
    auth,
  } = props;

  //check the optional time dimension is a valid one
  const hasDefaultTimeDimension = Object.values(TIMES).some(
    time => time === defaultTimeDimension
  );

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [userDimension, setUserDimension] = useState(
    userFilter ? USER_TYPES.ALL : null
  );
  const [timeDimension, setTimeDimension] = useState(
    timeFilter
      ? TIMES.WEEK
      : hasDefaultTimeDimension
      ? defaultTimeDimension
      : null
  );

  const updating = data && loading;

  //custom analytics from CDN bucket
  const { data: gaData, isLoading: gaIsLoading } = useCustomAnalyticsApi(
    endpoint,
    {
      isCustomAnalytics,
      auth,
      organisationId,
      timeFilter,
      timeDimension,
    }
  );
  //sql db endpoints
  const { data: dbData, isLoading: dbIsLoading } = useDbAnalyticsApi(endpoint, {
    isDbAnalytics: !isCustomAnalytics,
    organisationId,
    timeDimension,
    auth,
  });

  //render analytics sql data
  useEffect(() => {
    setLoading(dbIsLoading);
    if (dbData) {
      setData(dataRenderer({ allData: dbData, timeDimension }));
    }
  }, [dbData, dbIsLoading, dataRenderer, timeDimension]);

  //render custom analytics data
  useEffect(() => {
    setLoading(gaIsLoading);

    if (gaData) {
      setData(
        dataRenderer({
          allData: gaData,
          timeDimension,
          userDimension,
          title: legend || title,
          rowFormatter,
        })
      );
    }
  }, [
    gaData,
    gaIsLoading,
    dataRenderer,
    legend,
    rowFormatter,
    timeDimension,
    title,
    userDimension,
  ]);

  const isEmpty = !loading && !(data?.length >= 2);
  const chartHeight =
    chartType === "PieChart" ? PIE_CHART_HEIGHT : LINE_CHART_HEIGHT;

  const cssClass = classNames("chart", {
    "chart--half": halfWidth,
    "chart--empty": isEmpty,
    "chart--piechart": chartType === "PieChart",
  });

  const style = {
    height: chartHeight,
  };

  let o = {
    title,
    isStacked,
    backgroundColor: { fill: "transparent" },
    min,
    fontName: "Roboto",
  };

  if (chartType === "PieChart") {
    o = {
      ...o,
      pieHole: 0.4,
      is3D: false,
      tooltip: {
        text: "percentage",
      },
    };
  }

  if (chartType === "AreaChart" || chartType === "LineChart") {
    o = {
      ...o,
      annotations: { column_id: { style: "line" } },
      hAxis: {
        textStyle: {
          fontFamily: "Roboto",
          color: updating ? "transparent" : null,
          fontSize: 14,
        },
      },
    };
  }

  return (
    <div className={cssClass} style={style}>
      {isEmpty ? <EmptyState title={title} /> : null}
      {updating || loading ? (
        <div className="updating">
          {updating ? `Updating` : null}
          <Loader />
        </div>
      ) : null}
      {timeFilter ? (
        <Toggle
          options={TIME_TOGGLE_OPTIONS}
          value={timeDimension}
          onChange={setTimeDimension}
        />
      ) : null}
      {userFilter ? (
        <Toggle
          options={USER_TOGGLE_OPTIONS}
          value={userDimension}
          onChange={setUserDimension}
        />
      ) : null}
      <div className="chart__chart">
        <GAChart
          data={data}
          width="100%"
          height={`${chartHeight}px`}
          chartType={chartType}
          options={o}
          legendToggle={legendToggle}
        />
      </div>
    </div>
  );
}

Chart.defaultProps = {
  chartType: "AreaChart",
  dataRenderer: defaultRenderer,
};

Chart.propTypes = {
  auth: PropTypes.shape({
    expiresAt: PropTypes.number,
    getAuth: PropTypes.func,
    urls: PropTypes.array,
  }),
};

export default Chart;
