import React, { useState } from "react";
import TextBox from "../../../../common/components/Forms/TextBox";
import { BUTTON_DOMAIN } from "../../../../config";
import Modal from "../../../../common/components/Modal";

export default function AddButton(props) {
  const { onClose, organisationId, onAdd, addErrorMessage } = props;

  const [submitting, setSubmitting] = useState(false);
  const [buttonName, setButtonName] = useState("");

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const response = await fetch(
        `${BUTTON_DOMAIN}/admin/${organisationId}/buttons`,
        {
          credentials: "include",
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: buttonName }),
        }
      );
      if (response.status === 201) {
        const button = await response.json();
        onAdd(button);
        onClose();
      } else {
        throw new Error();
      }
    } catch (err) {
      addErrorMessage(
        "Your button could not be created, please refresh and try again"
      );
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title="Add a new Button"
      onSubmit={handleSubmit}
      submitDisabled={!buttonName.length}
      submitLabel="Add"
      onClose={onClose}
      submitLoading={submitting}
      closeLabel="Cancel">
      <div style={{ padding: "40px 40px 0" }}>
        <TextBox
          value={buttonName}
          disabled={submitting}
          label="Button name"
          autoFocus
          onChange={setButtonName}
        />
      </div>
    </Modal>
  );
}
