import { Record, List, fromJS } from "immutable";

import {
  SET_ADMINS_LOADED,
  RECEIVE_ADMINS_DATA,
  SET_SHOW_ADMIN_IMPORT,
  SET_IMPORTING_ADMINS,
  ADD_ADMINS,
  REMOVE_ADMINS,
  SET_SORT_BY,
  TOGGLE_ADMIN_SELECTION,
  SELECT_ADMINS,
  DESELECT_ADMINS,
  DESELECT_ALL_ADMINS,
  RESET_ADMINS_STORE,
  SET_ADMINS_TO_REMOVE,
  SET_REMOVING_ADMINS,
} from "./action-types";

import { SIGN_OUT_SUCCESS } from "../auth";

const defaultSortBy = "email.ASC";

export const AdminsState = new Record({
  loaded: false,
  admins: new List(),
  selectedAdmins: new List(),
  sortBy: defaultSortBy,
  defaultSortBy,
  adminsToRemove: null,
  removingAdmins: false,
  showAdminImport: false,
  importing: false,
});

export function adminsReducer(state = new AdminsState(), { payload, type }) {
  switch (type) {
    case SET_ADMINS_LOADED:
      return state.merge({ loaded: payload });
    case RECEIVE_ADMINS_DATA:
      return state.merge({ admins: payload });
    case ADD_ADMINS:
      let addedAdmins = state.admins;
      payload.forEach(admin => {
        addedAdmins = addedAdmins.push(fromJS(admin));
      });
      return state.merge({
        admins: addedAdmins,
      });
    case REMOVE_ADMINS:
      return state.merge({
        admins: state.admins.filter(admin => {
          return payload.indexOf(admin.get("email")) === -1;
        }),
      });
    case SET_SORT_BY:
      return state.merge({ sortBy: payload ? payload : state.defaultSortBy });
    case TOGGLE_ADMIN_SELECTION:
      let nextSelection;
      payload.forEach(adminEmail => {
        const selectedIndex = state.selectedAdmins.indexOf(adminEmail);
        if (selectedIndex > -1) {
          nextSelection = state.selectedAdmins.delete(selectedIndex);
        } else {
          nextSelection = state.selectedAdmins.push(adminEmail);
        }
      });
      return state.merge({ selectedAdmins: nextSelection });
    case SELECT_ADMINS:
      let selectedAdmins = state.selectedAdmins;
      payload.forEach(adminEmail => {
        if (selectedAdmins.indexOf(adminEmail) < 0) {
          selectedAdmins = selectedAdmins.push(adminEmail);
        }
      });
      return state.merge({ selectedAdmins });
    case DESELECT_ADMINS:
      let deselectedAdmins = state.selectedAdmins;
      payload.forEach(adminEmail => {
        const i = deselectedAdmins.indexOf(adminEmail);
        if (i > -1) {
          deselectedAdmins = deselectedAdmins.delete(i);
        }
      });
      return state.merge({ selectedAdmins: deselectedAdmins });
    case DESELECT_ALL_ADMINS:
      return state.merge({ selectedAdmins: new List() });
    case SET_ADMINS_TO_REMOVE:
      return state.merge({ adminsToRemove: payload });
    case SET_REMOVING_ADMINS:
      return state.merge({ removingAdmins: payload });
    case SET_SHOW_ADMIN_IMPORT:
      return state.merge({ showAdminImport: payload });
    case SET_IMPORTING_ADMINS:
      return state.merge({ importing: payload });

    case SIGN_OUT_SUCCESS:
    case RESET_ADMINS_STORE:
      return new AdminsState();
    default:
      return state;
  }
}
