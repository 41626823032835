import React, { Component, Fragment } from "react";
import Table from "../../table";
import LicenseIndicator from "../license-indicator";
import SelectionHeader from "../../selection-header";
import SelectionHeaderLabel from "../../selection-header/label";
import DropdownMenu from "../../ui/dropdown-menu";
import HeaderButton from "../../button/header";
import TruncatedText from "../../ui/truncated-text";
import RowActions from "../row-actions";
import EmptyState from "../../empty-state";
import MenuItems from "../menu-items";
import Certification from "./certification";
import CSVExport from "../../csv-export";
import Name from "./name";
import Student from "./student";
import { sortRows } from "../../table/row-sort";
import { LibraryMenu } from "../library-menu";
import { getAvatarUrl } from "../library-menu/utils";
import { OrganisationRole } from "../library-menu/const";

export default class TeacherTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTeacherLibrary: null,
      showTeacherLibraries: false,
      tableData: {
        headings: [
          {
            name: "Name",
            sortable: "displayName",
          },
          {
            name: "Email",
            sortable: "email",
          },
          {
            name: "Signed up",
            sortable: "pending",
            width: 170,
          },
          {
            name: "License",
            sortable: "license",
          },
          {
            name: "Certification",
            sortable: "certification",
            tooltip:
              'The Book Creator Certified Author course teaches the basics of using Book Creator in 1 hour. Your teachers can access this PD through their Dashboard. Find out more at <a href="https://app.bookcreator.com/certification" target="_blank">app.bookcreator.com/certification</a>',
          },
          {
            name: "",
            width: 100,
          },
        ],
        body: null,
      },
      sortBy: props.sortBy ? props.sortBy : props.defaultSortBy,
      bulkAction: null,
      menuOpen: null,
      showHeaderLicenses: false,
      teachersToRemove: null,
      tablePageSize: 0,
      currentTablePage: 0,
      tableRows: [],
    };
  }

  showLibraryMenu = teacher => {
    const isFeatureEnabled = this.props.featureFlags.visitLibraries;
    if (isFeatureEnabled) {
      this.setState({
        showTeacherLibraries: true,
        selectedTeacherLibrary: teacher,
      });
    }
  };

  handleSetUsersToDowngrade = users => {
    const { setUsersToDowngrade } = this.props;
    this.handleDeselectAllTeachers();
    this.handleRowSelect(users);
    setUsersToDowngrade(users);
  };

  getTeacherRows = props => {
    const {
      hasDomainLicense,
      isApplyToAllLicense,
      isBookCreatorUser,
      readonly,
      teachers,
      selectedTeachers,
      setLicensesToAssign,
      setLicensesToRemove,
      licenseInfo,
      setEmailTemplate,
      setTeachersToRemove,
      setTeachersToEmail,
      featureFlags,
      toggleLibraryManager,
    } = props;

    const { menuOpen } = this.state;

    return teachers.map(teacher => {
      const teacherJS = teacher.toJS();
      const {
        id,
        displayName,
        email,
        license,
        pending,
        certifying,
        badges,
        isAmbassador,
        isStudent,
        role,
      } = teacherJS;
      let allowedBookCount,
        bookQuota,
        libraryQuota = 0;
      if (license && licenseInfo) {
        const licenseData = licenseInfo.find(item => item.id === license.id);
        if (licenseData) {
          bookQuota = licenseData.bookQuota;
          libraryQuota = licenseData.libraryQuota;
          allowedBookCount =
            libraryQuota === "UNLIMITED" ? bookQuota : bookQuota * libraryQuota;
        }
      }
      return {
        ...teacherJS,
        selected: selectedTeachers.indexOf(id) > -1,
        warning: isStudent,
        disabled: pending,
        sortData: {
          displayName: displayName ? displayName : "",
          email,
          pending,
          license: allowedBookCount,
          certification: badges
            ? badges.calevel1 || badges.calevel2
            : certifying
            ? 1
            : 0,
        },
        rowId: id,
        cells: [
          <Name
            id={id}
            isAmbassador={isAmbassador}
            isLibraryManager={role === OrganisationRole.MANAGER}
            avatarUrl={getAvatarUrl(id)}
            featureFlags={featureFlags}
            onAvatarClick={e => {
              e.stopPropagation();
              this.showLibraryMenu(teacherJS);
            }}>
            {displayName}
          </Name>,
          isStudent ? (
            <Student>{email}</Student>
          ) : (
            <a href={`mailto:${email}`} className="fs-mask">
              {email}
            </a>
          ),
          <TruncatedText>{pending ? "No" : "Yes"}</TruncatedText>,
          <LicenseIndicator
            bookQuota={bookQuota}
            libraryQuota={libraryQuota}
          />,
          <Certification certifying={certifying} badges={badges} />,
          !readonly ? (
            <RowActions
              hasDomainLicense={hasDomainLicense}
              isApplyToAllLicense={isApplyToAllLicense}
              isBookCreatorUser={isBookCreatorUser}
              menuOpen={menuOpen}
              teacher={teacherJS}
              featureFlags={featureFlags}
              licenseInfo={licenseInfo}
              role={role}
              showViewProfile={!!featureFlags?.visitLibraries}
              setViewProfile={this.showLibraryMenu}
              setEmailTemplate={setEmailTemplate}
              setLicensesToAssign={setLicensesToAssign}
              setLicensesToRemove={setLicensesToRemove}
              setTeachersToRemove={setTeachersToRemove}
              setTeachersToEmail={setTeachersToEmail}
              setUsersToDowngrade={this.handleSetUsersToDowngrade}
              toggleLibraryManager={toggleLibraryManager}
            />
          ) : null,
        ],
      };
    });
  };

  componentWillMount = () => {
    this.setState({
      tableData: {
        ...this.state.tableData,
        body: this.getTeacherRows(this.props),
      },
      sortBy: this.props.sortBy ? this.props.sortBy : this.props.defaultSortBy,
    });
  };

  componentWillReceiveProps = nextProps => {
    this.setState({
      tableData: {
        ...this.state.tableData,
        body: this.getTeacherRows(nextProps),
      },
      sortBy: nextProps.sortBy ? nextProps.sortBy : nextProps.defaultSortBy,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.selectedTeachers &&
      !this.props.selectedTeachers.size &&
      prevProps.selectedTeachers.size
    ) {
      this.resetBulkAction();
    }
  };

  openMenu = id => {
    this.setState({
      menuOpen: id,
    });
  };

  closeMenu = id => {
    if (this.state.menuOpen === id) {
      this.setState({
        menuOpen: null,
      });
    }
  };

  handleRowSelect = ids => {
    this.props.toggleTeacherSelection(ids);
  };

  handleSelectAll = ids => {
    this.props.selectTeachers(ids);
  };

  handleDeselectTeachers = ids => {
    this.props.deselectTeachers(ids);
  };

  handleDeselectAllTeachers = () => {
    this.props.deselectAllTeachers();
  };

  handleBulkAction = () => {
    const {
      selectedTeachers,
      setEmailTemplate,
      setLicensesToAssign,
      setLicensesToRemove,
      setTeachersToRemove,
      setTeachersToEmail,
      setUsersToDowngrade,
    } = this.props;
    const { bulkAction } = this.state;
    if (bulkAction.license) {
      const { license } = bulkAction;
      setLicensesToAssign({ license, userIds: selectedTeachers.toJS() });
    } else {
      switch (bulkAction) {
        case "Remove teacher":
          setTeachersToRemove(selectedTeachers.toJS());
          break;
        case "Remove license":
          setLicensesToRemove(selectedTeachers.toJS());
          break;
        case "Send welcome email":
          setTeachersToEmail(selectedTeachers.toJS());
          break;
        case "Request Certification":
          setTeachersToEmail(selectedTeachers.toJS());
          setEmailTemplate("CERTIFICATION");
          break;
        case "Switch to student":
          setUsersToDowngrade(selectedTeachers.toJS());
          break;
        default:
          return;
      }
    }
  };

  resetBulkAction = () => {
    this.setState({
      bulkAction: null,
    });
  };

  getHeaderMenuItems = () => {
    const {
      featureFlags,
      hasDomainLicense,
      isBookCreatorUser,
      licenseInfo,
    } = this.props;
    const { showHeaderLicenses } = this.state;

    return (
      <MenuItems
        featureFlags={featureFlags}
        hasDomainLicense={hasDomainLicense}
        isBookCreatorUser={isBookCreatorUser}
        licenseInfo={licenseInfo}
        showLicenseSubMenu={showHeaderLicenses}
        onLicenseParentClick={e => this.setState({ showHeaderLicenses: true })}
        onLicenseItemClick={license =>
          this.setState({ bulkAction: { license } })
        }
        onSendWelcomeEmailClick={e =>
          this.setState({ bulkAction: "Send welcome email" })
        }
        onRequestCertificationClick={e =>
          this.setState({ bulkAction: "Request Certification" })
        }
        onRemoveLicenseClick={e =>
          this.setState({ bulkAction: "Remove license" })
        }
        onRemoveTeacherClick={e =>
          this.setState({ bulkAction: "Remove teacher" })
        }
        onSetUsersToDowngrade={e =>
          this.setState({ bulkAction: "Switch to student" })
        }
      />
    );
  };

  getDropDownValue = () => {
    const { bulkAction } = this.state;
    if (bulkAction.license) {
      const { license } = bulkAction;
      return (
        <LicenseIndicator
          bookQuota={license.bookQuota}
          libraryQuota={license.libraryQuota}
          available={license.quantity - license.allocated}
        />
      );
    } else {
      return bulkAction;
    }
  };

  render() {
    const {
      selectedTeacherLibrary,
      showTeacherLibraries,
      tableData,
      bulkAction,
      sortBy,
      tableRows,
      tablePageSize,
    } = this.state;

    const {
      featureFlags,
      selectedTeachers,
      setSortBy,
      licenseInfo,
      hasTeachers,
      adminUser,
      admins,
    } = this.props;

    return (
      <div className="teacher-table-page">
        {selectedTeachers.size ? (
          <SelectionHeader onCancel={this.handleDeselectAllTeachers}>
            <SelectionHeaderLabel>
              <strong>{selectedTeachers.size}</strong>{" "}
              {selectedTeachers.size === 1 ? "teacher" : "teachers"} selected
            </SelectionHeaderLabel>
            <DropdownMenu
              placeholder="Bulk Actions"
              value={bulkAction}
              valueRenderer={this.getDropDownValue}
              width={168}
              maxWidth={250}
              onClose={e => this.setState({ showHeaderLicenses: false })}
              onChange={value => {
                this.setState({ bulkAction: value });
              }}>
              {this.getHeaderMenuItems()}
            </DropdownMenu>
            <HeaderButton
              onClick={this.handleBulkAction}
              disabled={bulkAction === null}>
              {bulkAction === "Send welcome email" ||
              bulkAction === "Request Certification"
                ? "Preview"
                : "Apply"}
            </HeaderButton>
          </SelectionHeader>
        ) : null}
        {(() => {
          if (!hasTeachers) {
            return (
              <EmptyState
                title="You don’t have any teachers in your organisation"
                body="When you import teachers, they’ll show up here. Once imported, you’ll be able to assign and manage their Book Creator licenses."
              />
            );
          }

          if (tableData.body.size) {
            return (
              <Fragment>
                <Table
                  data={tableData}
                  sortBy={sortBy}
                  selectable
                  rowHeight={58}
                  onRowSelect={this.handleRowSelect}
                  onSelectAll={this.handleSelectAll}
                  onDeselectAll={this.handleDeselectTeachers}
                  onDeselectRows={this.handleDeselectTeachers}
                  resetSelection={this.handleDeselectAllTeachers}
                  onHeadingClick={setSortBy}
                  selectedRows={selectedTeachers}
                  onPageSizeChange={tablePageSize =>
                    this.setState({ tablePageSize })
                  }
                  onRowsChange={tableRows => this.setState({ tableRows })}
                />
                <CSVExport
                  allRows={sortRows(tableData.body, sortBy).toJS()}
                  currentRows={tableRows}
                  selectedTeachers={selectedTeachers}
                  tablePageSize={tablePageSize}
                  licenseInfo={licenseInfo ? licenseInfo.toJS() : null}
                />
                {showTeacherLibraries ? (
                  <LibraryMenu
                    admins={admins}
                    adminUser={adminUser}
                    featureFlags={featureFlags}
                    teacher={selectedTeacherLibrary}
                    onClose={() =>
                      this.setState({ showTeacherLibraries: false })
                    }
                  />
                ) : null}
              </Fragment>
            );
          } else {
            return (
              <EmptyState
                title="No teachers found"
                body="Please review your search criteria."
              />
            );
          }
        })()}
      </div>
    );
  }
}
