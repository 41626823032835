import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  getLMSConfiguration,
  getLMSLoaded,
  getLMSGenerating,
  lmsActions,
} from "../../../core/lms";
import Header from "../../components/header";
import Content from "../../components/content";
import Loader from "../../components/ui/loader";
import LMSConfiguration from "../../components/lms/configuration";
// import LMSInstructions from "../../components/lms/instructions";
import Confirmations from "../../components/lms/confirmations";
import "./index.scss";

class LMS extends Component {
  componentWillMount() {
    this.props.loadCredentials();
  }

  render() {
    const {
      lmsLoaded,
      lmsConfiguration,
      generateCredentials,
      setRevokeConfirmationVisibility,
      lmsGenerating,
    } = this.props;

    return (
      <div className="lms">
        <Helmet title="LMS Integration" />
        <Header title="LMS Integration"></Header>
        <Content className="lms__content">
          <p className="lms__intro">
            Book Creator can be integrated with your LMS using the Learning
            Tools Interoperability (LTI) protocol. Once configured teachers and
            students can launch Book Creator from your LMS without needing to
            create an account or sign in.
          </p>
          <div className="lms__sections">
            <div className="lms__section lms__section--config">
              <h2 className="lms__section-title">LTI Configuration</h2>
              {lmsLoaded ? (
                <LMSConfiguration
                  {...lmsConfiguration}
                  generateCredentials={generateCredentials}
                  lmsGenerating={lmsGenerating}
                  setRevokeConfirmationVisibility={
                    setRevokeConfirmationVisibility
                  }
                />
              ) : (
                <Loader colour="white" center />
              )}
            </div>
            {/*
            <div className="lms__section lms__section--instructions">
              <h2 className="lms__section-title">Instructions for your LMS</h2>
              <LMSInstructions />
            </div> */}
          </div>
          <Confirmations />
        </Content>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  getLMSLoaded,
  getLMSConfiguration,
  getLMSGenerating,
  (lmsLoaded, lmsConfiguration, lmsGenerating) => ({
    lmsLoaded,
    lmsConfiguration,
    lmsGenerating,
  })
);

const mapDispatchToProps = {
  ...lmsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(LMS);
