import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  teacherActions,
  getAllTeachers,
  getTeachersLoaded,
  getSelectedTeachers,
  getSortBy,
  getConfirmations,
  getShowTeacherImport,
  getImportingTeachers,
} from "../../../core/teachers";
import {
  getHasDomainLicense,
  getIsApplyToAllLicense,
  getLicenseInfo,
  getOrganisationId,
} from "../../../core/organisation";
import { getOrganisationFeatureFlags } from "../../../core/organisation";
import { getIsBookCreatorUser, getUserId, getEmail } from "../../../core/auth";
import { getAdmins, adminsActions } from "../../../core/admins";

import Loader from "../../components/ui/loader";
import Header, { HeaderActions } from "../../components/header";
import HeaderButton from "../../components/button/header";
import Content from "../../components/content";
import TeacherTable from "../../components/teachers/teacher-table";
import TeacherConfirmations from "../../components/teachers/confirmations";
import EmailImport from "../../components/email-import";
import SearchBox from "../../components/forms/search-box";

interface IState {
  windowWidth: number;
  searchText: string;
}

class Teachers extends Component<any, IState> {
  state = {
    windowWidth: window.innerWidth,
    searchText: "",
  };

  componentWillMount() {
    this.props.loadTeachers();
    this.props.loadAdmins();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    this.props.reset();
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ ...this.state, windowWidth: window.innerWidth });
  };

  setSearchText = (searchText: string) => {
    this.setState({ ...this.state, searchText });
  };

  render() {
    const {
      deselectAllTeachers,
      deselectTeachers,
      hasDomainLicense,
      isApplyToAllLicense,
      importingTeachers,
      importTeachers,
      isBookCreatorUser,
      adminUser,
      featureFlags,
      admins,
      licenseInfo,
      selectedTeachers,
      selectTeachers,
      setEmailTemplate,
      setLicensesToAssign,
      setLicensesToRemove,
      setShowTeacherImport,
      setSortBy,
      setTeachersToEmail,
      setTeachersToRemove,
      setUsersToDowngrade,
      showTeacherImport,
      sortBy,
      teachers,
      teachersLoaded,
      toggleLibraryManager,
      toggleTeacherSelection,
    } = this.props;

    const { windowWidth } = this.state;
    const searchText = this.state.searchText.trim();

    const showExportOnRight = windowWidth > 1150;
    const hasTeachers = teachers.size > 0;

    let filteredTeachers = teachers;

    if (searchText.length > 0) {
      filteredTeachers = teachers.filter(t => {
        const email = t.get("email");
        const displayName = t.get("displayName");
        const searchable: string[] = [email, displayName].filter(v => v);
        const result = searchable.find(s =>
          s.toLowerCase().includes(searchText.toLowerCase())
        );
        return result;
      });
    }

    return (
      <div>
        <Helmet title="Teacher dashboard" />
        <Header title="Teachers">
          {!hasDomainLicense && !showExportOnRight ? (
            <HeaderActions>
              <div id="page-nav-container" /> {/* Portal target */}
            </HeaderActions>
          ) : null}
          {!hasDomainLicense ? (
            <HeaderActions right>
              {showExportOnRight ? <div id="page-nav-container" /> : null}{" "}
              {/* Portal target */}
              <HeaderButton onClick={e => setShowTeacherImport(true)}>
                Import Teachers
              </HeaderButton>
            </HeaderActions>
          ) : (
            <HeaderActions right>
              <div id="page-nav-container" /> {/* Portal target */}
            </HeaderActions>
          )}
        </Header>
        <Content>
          <SearchBox
            value={this.state.searchText}
            onChange={value => this.setSearchText(value)}
            placeholder="Search by email or name"
            style={{ maxWidth: "265px" }}
          />
          {teachersLoaded ? (
            <TeacherTable
              teachers={filteredTeachers}
              hasTeachers={hasTeachers}
              selectedTeachers={selectedTeachers}
              licenseInfo={licenseInfo}
              sortBy={sortBy}
              setSortBy={setSortBy}
              selectTeachers={selectTeachers}
              deselectTeachers={deselectTeachers}
              deselectAllTeachers={deselectAllTeachers}
              hasDomainLicense={hasDomainLicense}
              isApplyToAllLicense={isApplyToAllLicense}
              isBookCreatorUser={isBookCreatorUser}
              adminUser={adminUser}
              featureFlags={featureFlags}
              admins={admins}
              toggleLibraryManager={toggleLibraryManager}
              toggleTeacherSelection={toggleTeacherSelection}
              setEmailTemplate={setEmailTemplate}
              setLicensesToAssign={setLicensesToAssign}
              setLicensesToRemove={setLicensesToRemove}
              setTeachersToRemove={setTeachersToRemove}
              setTeachersToEmail={setTeachersToEmail}
              setUsersToDowngrade={setUsersToDowngrade}
            />
          ) : (
            <Loader colour="white" center />
          )}
        </Content>
        <TeacherConfirmations />
        {showTeacherImport ? (
          <EmailImport
            title="Import Teachers"
            onSubmit={teachers => importTeachers({ teachers })}
            importing={importingTeachers}
            assignLicenses
            userType="teachers"
            onClose={() => setShowTeacherImport(false)}
          />
        ) : null}
      </div>
    );
  }
}

const adminUserProps = createSelector(
  getUserId,
  getEmail,
  getOrganisationId,
  getOrganisationFeatureFlags,
  getAdmins,
  (userId, email, orgId, featureFlags, admins) => ({
    adminUser: { userId, email, orgId },
    featureFlags,
    admins,
  })
);

const stateToProps = createSelector(
  getAllTeachers,
  getHasDomainLicense,
  getIsApplyToAllLicense,
  getIsBookCreatorUser,
  getTeachersLoaded,
  getSelectedTeachers,
  getSortBy,
  getConfirmations,
  getShowTeacherImport,
  getImportingTeachers,
  getLicenseInfo,
  (
    teachers,
    hasDomainLicense,
    isApplyToAllLicense,
    isBookCreatorUser,
    teachersLoaded,
    selectedTeachers,
    sortBy,
    confirmations,
    showTeacherImport,
    importingTeachers,
    licenseInfo
  ) => ({
    teachers,
    hasDomainLicense,
    isApplyToAllLicense,
    isBookCreatorUser,
    teachersLoaded,
    selectedTeachers,
    sortBy,
    confirmations,
    showTeacherImport,
    importingTeachers,
    licenseInfo,
  })
);

const mapStateToProps = createSelector(
  stateToProps,
  adminUserProps,
  (stateToProps, adminUserProps) => ({
    ...adminUserProps,
    ...stateToProps,
  })
);

const mapDispatchToProps = {
  ...teacherActions,
  ...adminsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Teachers);
