import React, { Component } from "react";
import flow from "lodash.flow";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import {
  isAuthenticated,
  authActions,
  getAuth,
  getEmailUnverified,
} from "../../../core/auth";
import { getOrganisationLoaded } from "../../../core/organisation";
import { cutTheMustard } from "../../../utils/browser";
import BookCreatorLogoColour from "../../../images/book-creator-logo-colour.png";
import SignIn from "../../components/sign-in/sign-in";
import Office365 from "../../components/sign-in/office-365";
import SignInEmail from "../../components/sign-in/sign-in-with-email";
import VerifyEmail from "../../components/sign-in/verify";
import Register from "../../components/sign-in/register";
import ForgottenPassword from "../../components/sign-in/forgotten-password";
import OrganisationPicker from "../../components/sign-in/organisation-picker";
import "./index.scss";

class SignInPage extends Component {
  state = {
    orgRedirect: false,
  };

  componentWillReceiveProps = nextProps => {
    this.checkAuth(nextProps);
  };

  componentWillMount = () => {
    this.checkBrowser(this.props);
    this.checkAuth(this.props);
  };

  checkBrowser = props => {
    const { history } = this.props;
    if (!cutTheMustard) {
      history.push("/unsupported");
    }
  };

  checkAuth = props => {
    const {
      auth,
      authenticated,
      history,
      organisationLoaded,
      location,
      unverified,
    } = props;
    if (authenticated && organisationLoaded) {
      history.push("/");
    } else if (
      authenticated &&
      !organisationLoaded &&
      location.pathname !== "/sign-in/organisation"
    ) {
      this.setState({
        orgRedirect: true,
      });
    } else if (
      unverified &&
      location.pathname !== "/sign-in/verify-email" &&
      !location.state?.loginRedirect
    ) {
      history.push("/sign-in/verify-email");
    } else if (!this.props.auth.error && auth.error) {
      history.push("/sign-in");
    }
  };

  get returnUrl() {
    const { location } = this.props;
    return (location.state && location.state.from) || "/";
  }

  render = () => {
    const { orgRedirect } = this.state;
    const { authenticated, organisationLoaded, location } = this.props;

    if (
      authenticated &&
      orgRedirect &&
      location.pathname !== "/sign-in/organisation"
    ) {
      return (
        <Redirect
          to={{
            pathname: "/sign-in/organisation",
            state: { from: this.returnUrl },
          }}
        />
      );
    }

    if (authenticated && organisationLoaded) {
      return <Redirect to={this.returnUrl} />;
    }

    return (
      <div className="sign-in">
        <div className="sign-in__content">
          <h1 className="sign-in__logo">
            <img src={BookCreatorLogoColour} alt="Book Creator" />
          </h1>
          <Switch>
            <PropsRoute
              exact
              path="/sign-in"
              component={SignIn}
              {...this.props}
            />
            <PropsRoute
              exact
              path="/sign-in/office-365"
              component={Office365}
              {...this.props}
            />
            <PropsRoute
              exact
              path="/sign-in/register"
              component={Register}
              {...this.props}
            />
            <PropsRoute
              exact
              path="/sign-in/register/:email"
              component={Register}
              {...this.props}
            />
            <PropsRoute
              exact
              path="/sign-in/email"
              component={SignInEmail}
              {...this.props}
            />
            <PropsRoute
              exact
              path="/sign-in/email/:email"
              component={SignInEmail}
              {...this.props}
            />
            <PropsRoute
              exact
              path="/sign-in/verify-email"
              component={VerifyEmail}
              {...this.props}
            />
            <PropsRoute
              path="/sign-in/forgotten-password"
              component={ForgottenPassword}
              {...this.props}
            />
            <PropsRoute
              path="/sign-in/forgotten-password/:email"
              component={ForgottenPassword}
              {...this.props}
            />
            <PropsRoute
              exact
              path="/sign-in/organisation"
              component={OrganisationPicker}
              returnUrl={this.returnUrl}
              loadOrgsOnMount
              {...this.props}
            />
          </Switch>
        </div>
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  isAuthenticated,
  getAuth,
  getOrganisationLoaded,
  getEmailUnverified,
  (authenticated, auth, organisationLoaded, unverified) => ({
    authenticated,
    auth,
    organisationLoaded,
    unverified,
  })
);

const mapDispatchToProps = {
  ...authActions,
};

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={routeProps => {
        return renderMergedProps(component, routeProps, rest);
      }}
    />
  );
};

const decorators = flow([
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
]);

export default decorators(SignInPage);
