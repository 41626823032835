import React, { Component } from "react";
import Helmet from "react-helmet";
import { getEmailError } from "../../../utils/validators";
import { firebaseAuth, sendPasswordResetEmail } from "../../../core/firebase";
import Form from "../../components/forms/form";
import FormButton from "../../components/forms/form-button";
import TextBox from "../../components/forms/text-box";

class ForgottenPassword extends Component {
  constructor(props) {
    super(props);
    const { email } = props.computedMatch.params;
    this.state = {
      email: email ? email : "",
      emailSent: false,
      submitting: false,
    };
  }

  componentWillUnmount() {
    this.props.clearAuthError();
  }

  updateEmail = nextEmail => {
    this.setState({
      email: nextEmail,
    });
  };

  handleSubmit = e => {
    this.setState({
      submitted: true,
    });
    if (this.isValid()) {
      this.setState({
        submitting: true,
      });
      this.sendResetEmail();
    }
    e.preventDefault();
  };

  sendResetEmail = () => {
    const { email } = this.state;
    sendPasswordResetEmail(firebaseAuth, email).then(
      () => {
        this.setState({
          emailSent: true,
          submitting: false,
        });
      },
      error => {
        console.log(error);
      }
    );
  };

  isValid = () => {
    const { email } = this.state;
    return !getEmailError(email);
  };

  render() {
    const { email, submitted, emailSent, submitting } = this.state;

    const emailError = submitted && getEmailError(email);

    return (
      <Form header="Recover password" onSubmit={this.handleSubmit.bind(this)}>
        <Helmet title="Recover password" />
        {emailSent ? (
          <div className="form__content">
            <p>{`We have sent an email to ${email} with instructions to reset your password.`}</p>
          </div>
        ) : (
          <div className="form__content">
            <p>
              Get instructions sent to this email that explain how to reset your
              password
            </p>
            <TextBox
              label="Email"
              type="email"
              onChange={this.updateEmail.bind(this)}
              value={email}
              autoFocus
              error={emailError}
            />
          </div>
        )}
        {emailSent ? (
          <div className="form__actions">
            <FormButton href="/sign-in" text="Sign in" />
          </div>
        ) : (
          <div className="form__actions">
            <FormButton flat href="/sign-in" text="Cancel" />
            <FormButton type="submit" text="Send" loading={submitting} />
          </div>
        )}
      </Form>
    );
  }
}

export default ForgottenPassword;
