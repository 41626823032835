import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  getIsButtonConsumer,
  getOrganisationId,
} from "../../../core/organisation";
import { notificationsActions } from "../../../core/notifications";
import ButtonsPage from "./";

function ButtonsPageContainer(props) {
  return <ButtonsPage {...props} />;
}

const mapDispatchToProps = {
  ...notificationsActions,
};

const mapStateToProps = createSelector(
  getOrganisationId,
  getIsButtonConsumer,
  (organisationId, isButtonConsumer) => ({
    organisationId,
    isButtonConsumer,
  })
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonsPageContainer);
