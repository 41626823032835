import React from "react";
import Helmet from "react-helmet";
import Header from "../../../components/header";
import Content from "../../../components/content";
import Article, { H2, H3, DL } from "../../../components/article";
import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNight as dark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import InlineCode from "../../../components/inline-code";
import { BUTTON_SDK_DOMAIN, BUTTON_SDK_VERSION } from "../../../../config";
import { Link } from "react-router-dom";
const customStyle = { padding: "20px 30px", margin: "0 auto 2em" };

function ButtonSDKDocs(props) {
  return (
    <div className="button-sdk-docs-page">
      <Helmet title="Book Creator Button JS SDK" />
      <Header title="Book Creator Button JS SDK"></Header>
      <Content className="buttons-page__content">
        <Article>
          <p>
            The Book Creator button allows you to embed the Book Creator
            authoring and publication experience in your own site. This SDK
            serves as a production-ready lightweight Javascript implementation
            that you can drop in to your existing site, or as a reference
            implementation that demonstrates how to use the button API.
          </p>
          <H2>Concepts</H2>
          <DL>
            <dt>API Key</dt>
            <dd>
              An API Key controls access to the Book Creator button feature. A
              key is associated with one or more domains. You will only be able
              to use Book Creator button on a site hosted at one of these
              domains.
            </dd>
            <dd>
              A key is 64 hex characters <InlineCode>0-9</InlineCode> and{" "}
              <InlineCode>a-f</InlineCode>.
            </dd>
            <dd>
              You can create and expire keys at{" "}
              <Link to="/api-keys">{document.domain}/api-keys</Link>.
            </dd>
            <dt>Button ID</dt>
            <dd>
              A Button ID is used to identify a particular namespace within your
              app. You might create a button for each area of your app, or for a
              dev, test and production environment. You shouldn't need to create
              too many as you can specify Launch ID and User ID as well (see
              below).
            </dd>
            <dd>
              A Button ID is a string of 38 URL-safe base 64 characters{" "}
              <InlineCode>0-9</InlineCode>, <InlineCode>A-Z</InlineCode>,{" "}
              <InlineCode>a-z</InlineCode>, <InlineCode>-</InlineCode> and{" "}
              <InlineCode>_</InlineCode>.
            </dd>
            <dd>
              You can create a Button ID at{" "}
              <Link to="/api-keys">{document.domain}/buttons</Link>. They are
              immutable once created and cannot be deleted or expired.
            </dd>
            <dd>
              The combination of Button ID with Launch ID and User ID uniquely
              identifies an instance of a book within Book Creator.
            </dd>
            <dt>Launch ID</dt>
            <dd>
              A Launch ID is a string that you provide every time you launch
              Book Creator. You should choose a name that is meaningful to you,
              and uniquely identifies the particular book within your
              application, for example "Irving Middle School - English Class 8 -
              Creative Writing Exercise Term 2 - We're Not in Kansas Anymore".
            </dd>
            <dd>A Launch ID is up to 128 UTF-8 characters.</dd>
            <dd>
              The combination of Launch ID with Button ID and User ID uniquely
              identifies an instance of a book within Book Creator.
            </dd>
            <dt>User ID</dt>
            <dd>
              A User ID is a string that you provide every time you launch Book
              Creator. You should send a value that uniquely identifies a user
              in your system. You could send us an email address but we
              recommend that you send a pseudonymised value instead.
            </dd>
            <dd>A User ID is up to 128 UTF-8 characters.</dd>
            <dd>
              The combination of User ID with Launch ID and Button ID uniquely
              identifies an instance of a book within Book Creator.
            </dd>
          </DL>
          <H2>Embedding the SDK</H2>
          <p>
            The SDK provides you with simple methods for initializing buttons
            and launching Book Creator. The SDK can either be referenced in HTML
            or from your Javascript.
          </p>
          <H3>HTML</H3>
          <SyntaxHighlighter
            language="html"
            style={dark}
            customStyle={customStyle}>
            {`<script src="${BUTTON_SDK_DOMAIN}/button/v${BUTTON_SDK_VERSION}/api.js"></script>`}
          </SyntaxHighlighter>
          <H3>Javascript</H3>
          <SyntaxHighlighter
            language="javascript"
            style={dark}
            customStyle={customStyle}>
            {`(() => {
  const script = document.createElement("script");
  script.src = "${BUTTON_SDK_DOMAIN}/button/v${BUTTON_SDK_VERSION}/api.js"; 
  script.onload = () => {
    // API ready 
  }
  document.body.appendChild(script);
})( );`}
          </SyntaxHighlighter>
          <H2>Initializing buttons</H2>
          <p>
            Once the SDK has loaded you are ready to initialize buttons. You
            will need to have already set up an API key and Button ID from the
            admin app for this step.
          </p>
          <p>
            You need to call the SDKs <InlineCode>launch</InlineCode> method to
            initialise the book and launch the iframe. You could replace the{" "}
            <InlineCode>{"// API ready"}</InlineCode> comment in the example
            above with a click handler on a button that calls the code below:
          </p>
          <SyntaxHighlighter
            language="javascript"
            style={dark}
            customStyle={customStyle}>
            {`
window.BookCreator.Button.launch({
  apiKey:   "API Key here",
  buttonId: "Button ID here",
  launchId: "Your launch ID here",
  userId:   "Your unique user ID here",
  author:   "Author name here",
  title:    "Book title here",
  shape:    "landscape|portrait|square",
  comic:    true|false,
  onLaunch: ({ url, created }) => {
    // Called when the iframe launches
    console.log("ready", url, created);
  },
  onError: (err) => {
    // Error returned here if button not configured correctly
  }
});`}
          </SyntaxHighlighter>
          <p>
            The <InlineCode>onLaunch</InlineCode> callback will be called when
            the iframe is launched with two arguments:
          </p>
          <ul>
            <li>
              <InlineCode>url</InlineCode> - the url of the published book.
              Books are published immediately when created and changes made in
              the app are automatically saved to the published book.
            </li>
            <li>
              <InlineCode>created</InlineCode> - a boolean that indicates
              whether the book was created on this API call or if it is an
              existing book being re-opened
            </li>
          </ul>
        </Article>
      </Content>
    </div>
  );
}

export default ButtonSDKDocs;
