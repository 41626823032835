import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

export default function SvgIcon(props) {
  const { ariaHidden, className, icon, name, style, title } = props;

  const cssClasses = cx("svg-icon", {
    [`svg-icon--${name}`]: name,
    [className]: className,
  });
  const iconStyle = style ? style : null;

  return (
    <span
      className={cssClasses}
      aria-hidden={ariaHidden}
      title={title}
      aria-label={title || name}
      role="img"
      dangerouslySetInnerHTML={{ __html: icon }}
      style={iconStyle}
    />
  );
}

SvgIcon.propTypes = {
  ariaHidden: PropTypes.bool,
  cssClass: PropTypes.string,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
};
