export function getLMS(state) {
  return state.lms;
}

export function getLMSLoaded(state) {
  return getLMS(state).loaded;
}

export function getLMSGenerating(state) {
  return getLMS(state).generating;
}

export function getLMSConfiguration(state) {
  return getLMS(state).configuration.toJS();
}

export function getConfirmations(state) {
  const { confirmations } = getLMS(state);
  return confirmations.toJS ? confirmations.toJS() : confirmations;
}
