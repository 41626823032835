import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import Header, { HeaderActions } from "../../components/header";
import HeaderButton from "../../components/button/header";
import Content from "../../components/content";
import AddButton from "../../components/buttons/add";
import ButtonsTable from "../../components/buttons/table";
import { BUTTON_DOMAIN } from "../../../config";
import Loader from "../../../common/components/Loader";
import EmptyState from "../../components/empty-state";
import ButtonToDelete from "../../components/buttons/delete";
import EditButton from "../../components/buttons/edit";

function ButtonsPage(props) {
  const { isButtonConsumer, organisationId, addErrorMessage } = props;
  const [buttons, setButtons] = useState(null);
  const [buttonToEdit, setButtonToEdit] = useState(null);
  const [buttonToDelete, setButtonToDelete] = useState(null);
  const [showAddButton, setShowAddButton] = useState(false);

  useEffect(() => {
    const loadButtons = async () => {
      const response = await fetch(
        `${BUTTON_DOMAIN}/admin/${organisationId}/buttons`,
        {
          credentials: "include",
        }
      );
      switch (response.status) {
        case 200:
          const buttons = await response.json();
          setButtons(buttons);
          break;
        default:
          setButtons([]);
          addErrorMessage(
            "Buttons could not be loaded, please refresh and try again"
          );
          break;
      }
    };
    if (organisationId && isButtonConsumer) {
      loadButtons();
    }
  }, [organisationId, addErrorMessage, isButtonConsumer]);

  const handleAdd = button => {
    setButtons([...buttons, button]);
  };

  const handleDeleteSuccess = deletedButton => {
    setButtons(buttons.filter(button => button.id !== deletedButton.id));
  };

  const handleEditSuccess = editedButton => {
    setButtons(
      buttons.map(button => {
        if (button.id === editedButton.id) {
          return editedButton;
        } else {
          return button;
        }
      })
    );
  };

  return (
    <div className="buttons-page">
      <Helmet title="Buttons" />
      <Header title="Buttons">
        <HeaderActions right>
          <HeaderButton onClick={e => setShowAddButton(true)}>
            Create a Button
          </HeaderButton>
        </HeaderActions>
      </Header>
      <Content className="buttons-page__content">
        {!buttons ? <Loader center color="white" /> : null}
        {buttons && buttons.length === 0 ? (
          <EmptyState
            title="You haven't created any Buttons yet"
            body="They will be listed here when you have some"
          />
        ) : null}
        {buttons && buttons.length > 0 ? (
          <ButtonsTable
            buttons={buttons}
            setButtonToDelete={setButtonToDelete}
            setButtonToEdit={setButtonToEdit}
          />
        ) : null}
      </Content>
      {showAddButton ? (
        <AddButton
          onClose={() => setShowAddButton(false)}
          organisationId={organisationId}
          addErrorMessage={addErrorMessage}
          onAdd={handleAdd}
        />
      ) : null}
      {buttonToDelete ? (
        <ButtonToDelete
          onClose={() => setButtonToDelete(null)}
          buttonToDelete={buttonToDelete}
          organisationId={organisationId}
          onDeleteSuccess={handleDeleteSuccess}
          addErrorMessage={addErrorMessage}
        />
      ) : null}
      {buttonToEdit ? (
        <EditButton
          onClose={() => setButtonToEdit(null)}
          buttonToEdit={buttonToEdit}
          organisationId={organisationId}
          onSuccess={handleEditSuccess}
          addErrorMessage={addErrorMessage}
        />
      ) : null}
    </div>
  );
}

export default ButtonsPage;
