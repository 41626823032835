import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import Header from "../../components/header";
import Content from "../../components/content";
import Stats from "../../components/analytics/stats";
import Charts from "../../components/analytics/charts";
import {
  analyticsActions,
  getAnalyticsResourceAuthExpiry,
  getAnalyticsSignedURLs,
  getUserAnalyticsSignedURLs,
} from "../../../core/analytics";
import {
  getOrganisationId,
  getIsButtonConsumer,
} from "../../../core/organisation";
import "./index.scss";

function AnalyticsPage(props) {
  const {
    isButtonConsumer,
    organisationId,
    analyticsResourceAuthExpiry,
    setResourceAuth,
    analyticsSignedURLs,
    userAnalyticsSignedURLs,
  } = props;

  return (
    <div>
      <Header title="Analytics" />
      <Content>
        <div className="analytics">
          <Stats
            auth={{
              expiresAt: analyticsResourceAuthExpiry,
              getAuth: setResourceAuth,
              userUrls: userAnalyticsSignedURLs,
            }}
          />
          <Charts
            auth={{
              expiresAt: analyticsResourceAuthExpiry,
              getAuth: setResourceAuth,
              urls: analyticsSignedURLs,
              userUrls: userAnalyticsSignedURLs,
            }}
            isButtonConsumer={isButtonConsumer}
            organisationId={organisationId}
          />
        </div>
      </Content>
    </div>
  );
}

const mapStateToProps = createSelector(
  getIsButtonConsumer,
  getOrganisationId,
  getAnalyticsResourceAuthExpiry,
  getAnalyticsSignedURLs,
  getUserAnalyticsSignedURLs,
  (
    isButtonConsumer,
    organisationId,
    analyticsResourceAuthExpiry,
    analyticsSignedURLs,
    userAnalyticsSignedURLs
  ) => ({
    isButtonConsumer,
    organisationId,
    analyticsResourceAuthExpiry,
    analyticsSignedURLs,
    userAnalyticsSignedURLs,
  })
);

const mapDispatchToProps = {
  ...analyticsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsPage);
