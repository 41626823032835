export function getAdminsState(state) {
  return state.admins;
}

export function getAdmins(state) {
  const orgName = state.organisation.toJS().organisation?.name;
  return getAdminsState(state).admins.map(admin => {
    return admin.set("organisation", orgName);
  });
}

export function getAdminsLoaded(state) {
  return getAdminsState(state).loaded;
}

export function getShowAdminImport(state) {
  return getAdminsState(state).showAdminImport;
}

export function getImportingAdmins(state) {
  return getAdminsState(state).importing;
}

export function getSortBy(state) {
  return getAdminsState(state).sortBy;
}

export function getSelectedAdmins(state) {
  return getAdminsState(state).selectedAdmins;
}

export function getConfirmations(state) {
  const { adminsToRemove, removingAdmins } = getAdminsState(state);
  return {
    adminsToRemove,
    removingAdmins,
  };
}
