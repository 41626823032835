import React, { Component } from "react";
import Panel from "../../panel";
import SchoolIcon from "../../../../images/png/school.png";
import "./index.scss";

export default class OrganisationName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      focused: false,
    };
  }

  componentWillReceiveProps = nextProps => {
    if (!this.state.focused) {
      this.setState({
        name: nextProps.name,
      });
    }
  };

  handleInputChange = e => {
    this.setState({
      name: e.target.value,
    });
  };

  handleInputBlur = e => {
    const value = e.target.value.trim();
    this.setState({ focused: false }, () => {
      if (value !== this.props.name) {
        this.props.onBlur(value);
      }
    });
  };

  render() {
    const { organisationCount, setShowOrgPicker } = this.props;
    const { name } = this.state;
    return (
      <Panel className="organisation-name" title="Your Organization">
        <div className="organisation-name__icon">
          <img src={SchoolIcon} alt="" />
        </div>
        <div className="organisation-name__name">
          <input
            className="organisation-name__name-input fs-mask"
            onChange={this.handleInputChange}
            onBlur={this.handleInputBlur}
            onFocus={e => this.setState({ focused: true })}
            maxLength={40}
            value={name}
          />
          <pre className="organisation-name__name-display fs-mask">{name}</pre>
        </div>
        {organisationCount > 0 ? (
          <button
            onClick={() => setShowOrgPicker(true)}
            className="organisation-name__switch">
            Switch organisation
          </button>
        ) : null}
      </Panel>
    );
  }
}
