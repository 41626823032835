import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  getAdminsLoaded,
  getAdmins,
  getShowAdminImport,
  getImportingAdmins,
  getSelectedAdmins,
  getSortBy,
  adminsActions,
} from "../../../core/admins";
import Header, { HeaderActions } from "../../components/header";
import HeaderButton from "../../components/button/header";
import Content from "../../components/content";
import AdminsTable from "../../components/admins/admins-table";
import EmailImport from "../../components/email-import";
import Loader from "../../components/ui/loader";
import AdminConfirmations from "../../components/admins/confirmations";

class Admin extends Component {
  componentWillMount() {
    const { loadAdmins } = this.props;
    loadAdmins();
  }

  render() {
    const {
      adminsLoaded,
      showAdminImport,
      setShowAdminImport,
      importAdmins,
      importingAdmins,
      admins,
      selectedAdmins,
      sortBy,
      setSortBy,
      selectAdmins,
      deselectAdmins,
      deselectAllAdmins,
      toggleAdminSelection,
      setAdminsToRemove,
    } = this.props;

    return (
      <div>
        <Helmet title="Admin dashboard" />
        <Header title="Admin">
          <HeaderActions right>
            <HeaderButton onClick={e => setShowAdminImport(true)}>
              Add admins
            </HeaderButton>
          </HeaderActions>
        </Header>
        <Content>
          {adminsLoaded ? (
            <AdminsTable
              admins={admins}
              selectedAdmins={selectedAdmins}
              sortBy={sortBy}
              setSortBy={setSortBy}
              selectAdmins={selectAdmins}
              deselectAdmins={deselectAdmins}
              deselectAllAdmins={deselectAllAdmins}
              toggleAdminSelection={toggleAdminSelection}
              setAdminsToRemove={setAdminsToRemove}
            />
          ) : (
            <Loader colour="white" center />
          )}
        </Content>
        <AdminConfirmations />
        {showAdminImport ? (
          <EmailImport
            title="Add Admins"
            onSubmit={emails => importAdmins({ emails })}
            importing={importingAdmins}
            userType="admins"
            onClose={e => setShowAdminImport(false)}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  getAdminsLoaded,
  getAdmins,
  getShowAdminImport,
  getImportingAdmins,
  getSelectedAdmins,
  getSortBy,
  (
    adminsLoaded,
    admins,
    showAdminImport,
    importingAdmins,
    selectedAdmins,
    sortBy
  ) => ({
    adminsLoaded,
    admins,
    showAdminImport,
    importingAdmins,
    selectedAdmins,
    sortBy,
  })
);

const mapDispatchToProps = {
  ...adminsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
