import React, { Component } from "react";
import classNames from "classnames";
import MiniMenu from "../mini-menu";
import SvgIcon from "../../ui/svg-icon";
import ChevronIcon from "../../../../images/svg/chevron.svg";
import "./index.scss";

export default class DropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      timeout: null,
      width: props.width,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setWidth();
    }
  }

  setWidth = () => {
    this.setState({
      width: this._toggleText.scrollWidth + 38,
    });
  };

  handleToggleClick = e => {
    const { onToggleClick } = this.props;
    this.setState({
      open: !this.state.open,
    });
    if (onToggleClick) {
      onToggleClick(e);
    }
    e.stopPropagation();
    e.preventDefault();
  };

  handleMouseLeave = () => {
    if (this.state.open && !this.timeout) {
      this.setState({
        timeout: window.setTimeout(this.closeMenu, 2000),
      });
    }
  };

  handleMouseEnter = () => {
    window.clearTimeout(this.state.timeout);
    this.setState({
      timeout: null,
    });
  };

  closeMenu = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    if (this._isMounted) {
      this.setState({
        open: false,
      });
    }
  };

  getPosition = () => {
    return {
      x: this._container.offsetLeft,
      y: this._container.offsetTop,
    };
  };

  render() {
    const {
      children,
      value,
      valueRenderer,
      placeholder,
      maxWidth,
    } = this.props;

    const { open, width } = this.state;

    const containerClass = classNames("dropdown-menu", {
      "dropdown-menu--active": open,
    });

    const containerStyle = {
      width,
      maxWidth,
    };

    return (
      <div
        className={containerClass}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={containerStyle}
        ref={c => (this._container = c)}>
        <button
          onClick={this.handleToggleClick}
          className="dropdown-menu__toggle">
          <span>
            <span
              className="dropdown-menu__toggle-text"
              ref={c => (this._toggleText = c)}>
              {value && valueRenderer
                ? valueRenderer()
                : value
                ? value
                : placeholder}
            </span>
            <SvgIcon icon={ChevronIcon} iconName="chevron" />
          </span>
        </button>
        {open && children ? (
          <MiniMenu
            position={{ top: 0, left: 0 }}
            dropdown
            width={maxWidth && width > maxWidth ? maxWidth : width}
            onClose={this.closeMenu}
            onItemClick={this.closeMenu}>
            {children}
          </MiniMenu>
        ) : null}
      </div>
    );
  }
}
