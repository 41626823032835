import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./index.scss";

class SvgIcon extends Component {
  static propTypes = {
    icon: PropTypes.string,
  };

  render() {
    const { style, name, icon, cssClass, ariaHidden, ...props } = this.props;

    const cssClasses = classNames("icon", {
      [`icon--${name}`]: name,
      [`${cssClass}`]: cssClass,
    });

    return (
      <span
        className={cssClasses}
        aria-hidden={ariaHidden}
        dangerouslySetInnerHTML={{ __html: icon }}
        style={style || null}
        {...props}
      />
    );
  }
}

export default SvgIcon;
