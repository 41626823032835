import { Record, List } from "immutable";

import {
  RECEIVE_ORG_DATA,
  RECEIVE_ORG_LIST,
  LOAD_LICENSES_DATA_ATTEMPT,
  LOAD_LICENSES_DATA_ERROR,
  RECEIVE_LICENSE_DATA,
  SET_ORGANISATION_NAME,
  SET_SHOW_ORG_PICKER,
  LOAD_ORG_LIST,
} from "./action-types";

import { SIGN_OUT_SUCCESS } from "../auth";

export const OrganisationState = new Record({
  loaded: false,
  organisation: null,
  organisationList: [],
  organisationListLoading: false,
  organisationListLoaded: false,
  licenseError: false,
  licensesLoading: true,
  showOrgPicker: false,
});

export function organisationReducer(
  state = new OrganisationState(),
  { payload, type }
) {
  switch (type) {
    case RECEIVE_ORG_DATA:
      let organisation = null;
      if (payload) {
        const {
          id,
          name,
          licenses,
          isDomain,
          buttonAPIEnabled,
          features,
        } = payload;
        organisation = {
          id,
          name,
          licenses,
          isDomain,
          buttonAPIEnabled,
          features,
        };
      }
      return state.merge({
        organisation,
        loaded: true,
      });
    case LOAD_LICENSES_DATA_ATTEMPT:
      return state.merge({
        licensesLoading: true,
        licenseError: false,
      });
    case LOAD_ORG_LIST:
      return state.merge({
        organisationListLoading: true,
      });
    case LOAD_LICENSES_DATA_ERROR:
      return state.merge({
        licensesLoading: false,
        licenseError: true,
      });
    case RECEIVE_ORG_LIST:
      return state.merge({
        organisationListLoading: false,
        organisationListLoaded: true,
        organisationList: payload,
      });
    case RECEIVE_LICENSE_DATA:
      return state.merge({
        organisation: state.organisation.set("licenses", new List(payload)),
        licensesLoading: false,
        licenseError: false,
      });
    case SET_ORGANISATION_NAME:
      return state.merge({
        organisation: state.organisation.set("name", payload),
      });
    case SET_SHOW_ORG_PICKER:
      return state.merge({
        showOrgPicker: payload,
      });
    case SIGN_OUT_SUCCESS:
      return new OrganisationState();
    default:
      return state;
  }
}
