import React from "react";
import classNames from "classnames";
import "./index.scss";

const Checkbox = props => {
  const {
    className,
    children,
    reverse,
    checked,
    onClick,
    value,
    disabled,
  } = props;

  const cssClass = classNames("custom-checkbox", {
    "custom-checkbox__reverse": reverse,
    [className]: className,
  });

  return (
    <label class={cssClass} onClick={onClick}>
      <input
        type="checkbox"
        name="checkbox"
        checked={checked}
        value={value}
        disabled={disabled}
      />
      {children}
    </label>
  );
};

export default Checkbox;
